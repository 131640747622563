<template>
  <div class="partial_view">
    <el-form :model="form" ref="form">
      <div class="flow_list_container">
        <div class="flow_area">
          <div class="header">
            <div class="flow_label">{{$t('Label.Trading/Flow Name')}}</div>
            <div>{{$t('Label.Split Flow')}}</div>
          </div>
          <div class="flow" ref="flow">
            <div class="left" :style="scrollStyle">
              <div v-for="(flow, index) in form.partialInfo" :key="flow.uuid + '_' + index" class="flow_label" :class="{ first: index === 0, 'other-trading': flow.otherTradingFlg === OTHER_TRADING_FLG.ON}">
                <div class="between">
                  <div>
                    <span v-if="isMainFlow(flow)" class="main_label">Main Flow</span>
                    <span v-if="flow.otherTradingFlg === OTHER_TRADING_FLG.ON" class="other_label">Other</span>
                    <el-tooltip placement="top-start" popper-class="mcu" :tabindex="-1">
                      <div slot="content">{{flow.otherTradingName || tradingName}}</div>
                      <span class="trading_name">{{flow.otherTradingName || tradingName}}</span>
                    </el-tooltip>
                  </div>
                  <el-tooltip v-if="flow.tradingFlowId && flow.flowDetailViewableFlg === DETAIL_VIEWABLE_FLG.ON" placement="top-end" popper-class="mcu" :tabindex="-1">
                    <div slot="content" v-html="sanitize($t('Label.Trading Details'))" />
                    <tw-button type="secondary" size="small" icon="detail" class="button_detail" @click="$emit('show-flow-detail', flow)" />
                  </el-tooltip>
                </div>

                <el-form-item  v-if="!viewOnly && flow.editableFlg === EDITABLE_FLG.ON" label="Flow Name" :prop="`partialInfo[${index}].tradingManageName`" :rules="flowNameRules">
                  <el-input style="width: 100%" v-model="flow.tradingManageName" placeholder="" show-word-limit maxlength="100" :tabindex="isShowDetail || drawerShow ? '-1' : undefined" />
                </el-form-item>
                <tw-overflow-tooltip v-else class="flow_name" :content="flow.tradingManageName" />
                <img v-if="deletable(flow)" src="@/assets/images/icons/times_gray600.svg" class="close_times" @click="deleteFlowConfirm(flow)">
              </div>
            </div>
            <div class="right">
              <div v-for="(flow, index) in form.partialInfo" :key="flow.uuid + '_' + index" class="entities" :class="{ first: index === 0, new: newFlowUuid === flow.uuid, 'other-trading': flow.otherTradingFlg === OTHER_TRADING_FLG.ON}">
                <div class="entity" v-for="(entity, order) in entityNames" :key="entity.code + '_' + flow.uuid" :style="{width: `${entitySpanWidth}px`}">
                  <tw-partial-card-item v-if="getEntity(flow, entity.code)" :item="getEntity(flow, entity.code)" :flowIndex="index" :entityOrder="order" :viewOnly="viewOnly" :class="[entity.entityId]" @show-partial-detail="showPartialDetail" @partial="partial" />
                  <template v-if="getEntity(flow, entity.code) && prevEntity(getEntity(flow, entity.code))">
                    <tw-flow-line v-for="(prev, i) in getEntity(flow, entity.code).prevEntityStatus || []" :key="prev.prevUuid+ '_' + i + '_' + index" :entity="getEntity(flow, entity.code)" :prevEntity="prev" :flowIndex="index" :flowHeight="flowHeight" :entitySpanWidth="entitySpanWidth" :cardWidth="cardWidth" :lineMargin="lineMargin" :partialInfo="form.partialInfo" />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分納モード -->
      <el-drawer :visible.sync="isShowDetail" :append-to-body="true" custom-class="partial_detail" :before-close="closeDrawer" :size="700">
        <h2>{{$t('Label.Goods Quantity')}}</h2>
        <table class="goods_table" :style="{width: scrollerWidth, minWidth: scrollerWidth}" cellspacing="0" cellpadding="0">
          <colgroup>
            <col style="width:30%;">
            <col style="width:21%;">
            <col style="width:21%;">
            <col style="width:21%;">
            <col style="width:3%;">
          </colgroup>
          <tr>
            <th>{{$t('Label.Partial Goods Name')}}</th>
            <th>{{$t('Label.Remaining Qty')}}</th>
            <th>{{$t('Label.Qty')}}</th>
            <th colspan="2">{{$t('Label.Result')}}</th>
          </tr>
        </table>
        <div ref="scroller" class="table_scroller">
          <table class="goods_table" style="width: 100%; min-width: 100%" cellspacing="0" cellpadding="0">
            <colgroup>
              <col style="width:30%;">
              <col style="width:21%;">
              <col style="width:21%;">
              <col style="width:21%;">
              <col style="width:3%;">
            </colgroup>
            <!-- eslint-disable-next-line vue/require-v-for-key -->
            <tr v-for="(goods, index) in form.cloneGoodsAssignment" :class="{selected: selectGoodsIndex === index}" @click="selectGoodsIndex = index">
              <td style="white-space: nowrap">
                <tw-overflow-tooltip v-if="goods.goodsShortName" :content="goods.goodsShortName" />
              </td>
              <td class="quantity" :class="{minus: getRemainingQuantity(goods).total < 0}">{{getRemainingQuantity(goods).total | formatQuantity}}</td>
              <td class="quantity input_cell" v-if="goodsEditable">
                <el-form-item :prop="`cloneGoodsAssignment[${index}].quantity1`" :rules="quantityRules">
                  <TwInputFormatNumber v-model="goods.quantity1" />
                </el-form-item>
              </td>
              <td v-else class="quantity">{{(goods.quantity1 || 0) | formatQuantity}}</td>
              <td :colspan="goodsEditable ? 1 : 2" class="quantity" style="border-right: 0" :class="{minus: getRemainingQuantity(goods).result < 0}">{{getRemainingQuantity(goods).result | formatQuantity}}</td>
              <td v-if="goodsEditable" class="delete_cell">
                <img src="@/assets/images/icons/icon_close_600.svg" class="close" @click="deleteGoods(index)">
              </td>
            </tr>
          </table>
        </div>

        <!-- <template v-for="(goods, index) in form.cloneGoodsAssignment">
          <div class="goods">
            <div class="goods_title" :class="{empty: !goods.goodsShortName}">
              <span>{{goods.goodsShortName}}</span>
              <img v-if="goodsEditable" src="@/assets/images/icons/icon_close_600.svg" class="close" @click="deleteGoods(index)">
            </div>
            <el-form-item v-if="goodsEditable" :label="$t('Label.Quantity Partial')" :prop="`cloneGoodsAssignment[${index}].quantity1`" :rules="quantityRules">
              <TwInputFormatNumber v-model="goods.quantity1" />
            </el-form-item>
            <div v-else class="quantity">
              <label>{{$t('Label.Quantity Partial')}}</label>
              <span>{{goods.quantity1 | formatQuantity}}</span>
            </div>
            <div class="remaining_quantity">
              <label>{{$t('Label.Remaining Quantity')}}</label>
              <span :class="{minus: getRemainingQuantity(goods).result < 0}">{{getRemainingQuantity(goods).result | formatQuantity}}</span>
            </div>
          </div>
        </template> -->

        <el-select v-if="goodsEditable && addableGoods.length" class="section" placeholder="Select Goods" v-model="goodsModel" popper-class="goods_pulldown" @change="onGoodsSelect">
          <el-option
            v-for="(goods) in addableGoods"
            :key="goods.goodsLineId + '_' + goods.goodsLineSeq"
            :label="goods.goodsShortName || ' '"
            :value="goods">
          </el-option>
        </el-select>
        <div class="buttons">
          <tw-button v-if="goodsEditable" type="default" size="medium" @click="closeDrawer">Cancel</tw-button>
          <tw-button v-if="goodsEditable" type="primary" size="medium" @click="commitDrawer">OK</tw-button>
          <tw-button v-else type="default" size="medium" @click="closeDrawer">Close</tw-button>
        </div>
      </el-drawer>
    </el-form>
    <div class="buttons">
      <tw-button type="default" size="medium" @click="cancel">Cancel</tw-button>
      <!-- <tw-button type="primary" size="medium" @click="showSummary">Summary</tw-button> -->
      <tw-button v-if="viewOnly && editable" type="primary" size="medium" @click="edit">Edit</tw-button>
      <tw-button v-else type="primary" size="medium" @click="register">Register</tw-button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import BigNumber from 'bignumber.js';
import { $api } from '@/store/ApiClient';
import { EXTERNAL_USER_FLG_VARIABLES, SUBORDINATE_FLOW_FLG, CAN_PARTIAL_SHIPMENT_FLG, GOODS_ASSIGNMENT_PROHIBITED, DELETE_ABLE_FLG, EDITABLE_FLG, OTHER_TRADING_FLG, DETAIL_VIEWABLE_FLG, PARTIAL_API_USE_CODE, COMBINE_FLOW_FLG, MAIN_COMBINE_FLG } from 'lib-tw-common';
import { formatQuantity, generateUuid } from '@/utils/searchUtil.js';
import mixinEntityList from '@/utils/mixinEntityList.js';
import mixinValidation from '@/utils/mixinValidation.js';
import { entityNames } from '@/dictionaries/map.js';
import TwFlowLine from '@/components/atoms/TwFlowLine';
import TwInputFormatNumber from '@/components/atoms/TwInputFormatNumber';
import TwPartialCardItem from '@/components/molecules/TwPartialCardItem';
import TwOverflowTooltip from '@/components/atoms/TwOverflowTooltip';

export default {
  name: 'TwPartialView',
  mixins: [mixinEntityList, mixinValidation],
  props: {
    tradingId: String,
    tradingFlowId: String,
    editable: Boolean,
    mode: String,
    tradingName: String,
  },
  components: {
    TwPartialCardItem,
    TwFlowLine,
    TwInputFormatNumber,
    TwOverflowTooltip,
  },
  data() {
    return {
      entitySpanWidth: 169, // エンティティ表示枠の幅
      cardWidth: 117, // エンティティカードの幅
      flowHeight: 110,
      lineMargin: 8, // ラインのマージン
      activeTab: 1,
      form: {
        partialInfo: [],
        cloneGoodsAssignment: [],
      },
      parentGoodsAssignment: [],
      subordinateInfo: {},
      unAddableArea: [],
      activeTrading: [],
      activeIndex: 0,
      selectEntity: null,
      selectFlow: null,
      selectFlowIndex: null,
      isShowDetail: false,
      SUBORDINATE_FLOW_FLG,
      EDITABLE_FLG,
      DETAIL_VIEWABLE_FLG,
      flowNameRules: [
        {
          lakeelMessageSourceFlg: true,
          validationId: ['tw-isMaxLength100'],
          validator: this.onEventValidation, trigger: 'blur'
        }
      ],
      quantityRules: [
        {
          lakeelMessageSourceFlg: true,
          validationId: ['tw-isNotEmpty', 'tw-isNumber', 'tw-isIntegerDigit12', 'tw-isDecimalDigit4'],
          validator: this.onEventValidation, trigger: 'blur'
        }
      ],
      hasDiff: false, // 終了時の変更判別用フラグ
      originGoodsAssignment: [],
      goodsModel: null,
      scrollTop: 0,
      cancelable: false,
      viewOnly: true, // 参照モードか
      newFlowUuid: null, // 新しく追加されたフローのuuid
      backup: null, // 分納混載直後のキャンセル用
      backupOriginal: null,  // 分納混載直後のキャンセル用
      scrollerWidth: '100%',
      selectGoodsIndex: null,
      apiUseCode: PARTIAL_API_USE_CODE.PARTIAL,
      t: null, // タイマー
      combineStartFlowId: null, // 混載開始フローのフローID
      selectedFlowUuid: null, // 混載先のフロー
      selectedEntityTypeCd: null, // 混載先のエンティティ
      selectedEntityUncombinableInfo: [], // 混載先のエンティティの混載不可フロー情報
      checkedCombineFlows: [], // 混載のチェックを付けたフローのuuidの配列
      addedFlows: [], // 追加した別取引
      initialOthers: [], // 初期表示時の別取引
      OTHER_TRADING_FLG,
      isPreviewMode: false, // プレビューモード表示フラグ
    };
  },
  computed: {
    drawerShow() {
      return this.$store.state.drawerShow;
    },
    entityNames() {
      return _.map(entityNames);
    },
    getEntity() {
      return (flow, entityTypeCd) => {
        return _.find(flow.entityStatus, {entityTypeCd: entityTypeCd});
      };
    },
    prevEntity() {
      return entity => {
        if (_.isEmpty(entity.prevEntityStatus)) {
          return false;
        }
        return entity.prevEntityStatus[0];
      };
    },
    // 削除可否
    deletable() {
      return flow => {
        if (this.viewOnly) {
          return false;
        }
        return flow.deletableFlg === DELETE_ABLE_FLG.OK && flow.subordinateInfo.parentFlg !== SUBORDINATE_FLOW_FLG.ON;
      }
    },
    // +ボタンを非表示にするかを返却します
    isDisabled() {
      return (flow, entityTypeCd) => {
        if (this.viewOnly) {
          return true;
        }
        return !!flow.subordinateInfo.parentFlg && _.findIndex(flow.entityStatus, {entityTypeCd: entityTypeCd}) !== (flow.entityStatus.length - 1)
      };
    },
    // 残数量を返却します
    getRemainingQuantity() {
      return goods => {
        return this.calcRemainingQuantity(goods, this.selectFlowIndex, this.form.cloneGoodsAssignment);
      }
    },
    // 商品数量の編集可否を返却します
    goodsEditable() {
      if (this.viewOnly) {
        return false;
      }
      // 混載の時は編集不可
      if (this.apiUseCode === PARTIAL_API_USE_CODE.COMBINE) {
        return false;
      }
      return this.selectFlow && this.selectFlow.goodsAssignmentProhibited === GOODS_ASSIGNMENT_PROHIBITED.OFF;
    },
    // 追加可能な商品を返却します
    addableGoods() {
      return _.reject(this.getParentGoodsAssignment(this.selectFlowIndex), o => {
        return _.some(this.form.cloneGoodsAssignment, goods => this.isEqualGoods(goods, o));
      });
    },
    scrollStyle() {
      return {
        transform: `translateY(-${this.scrollTop}px)`,
      }
    },
    // メインフローかどうかを返却します
    isMainFlow() {
      return flow => {
        return flow.subordinateInfo.parentFlg === SUBORDINATE_FLOW_FLG.ON && flow.subordinateInfo.subFlg === SUBORDINATE_FLOW_FLG.OFF;
      };
    },
  },
  filters: {
    // 商品数量をフォーマットします
    formatQuantity(val) {
      return formatQuantity(val);
    },
  },
  created() {
    this.setWidth();
    this.viewOnly = !this.mode;
    this.fetch();
  },
  mounted() {
    this.watchScroll();
  },
  beforeDestroy() {
    cancelAnimationFrame(this.t);
  },
  methods: {
    sanitize(str) {
      return this.$options.filters.newlines(str);
    },
    watchScroll() {
      this.t = requestAnimationFrame(() => {
        if (this.scrollTop !== this.$refs.flow.scrollTop) {
          this.scrollTop = this.$refs.flow.scrollTop;
        }

        this.watchScroll();
      });
    },
    setWidth() {
      this.scrollerWidth = `calc(100% - ${this.$store.state.scrollbarWidth}px)`;
    },
    // 分納情報を取得します
    fetch(others = undefined) {
      // bff_td_13 分納管理初期表示
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/tradings/{companyId}/manages/partial/search',
          path: {
            companyId: this.$store.state.companyId,
          },
          query: {
            tradingId: this.tradingId,
            tradingFlowId: this.tradingFlowId,
            apiUseCode: this.apiUseCode,
            others: this.addedFlows,
          }
        }
      };

      $api.request(params)
      .then(res => {
        if (others) {
          // 混載の別取引フロー追加時は選択したフローの混載不可フロー情報を上書きする
          const selectedFlow = _.find(res.partialInfo, {otherTradingFlg: OTHER_TRADING_FLG.OFF, tradingFlowId: this.combineStartFlowId});
          const selectedEntity = _.find(selectedFlow.entityStatus, {entityTypeCd: this.selectedEntityTypeCd});
          if (selectedEntity) {
            this.selectedEntityUncombinableInfo = [...selectedEntity.uncombinableInfo];
          }

          // 混載の別取引フロー追加時は自取引のフローはレスポンスから除外する
          res.partialInfo = _.filter(res.partialInfo, 'otherTradingFlg');
        }
        let partialInfo = _.map(res.partialInfo, flow => {
          // 既存フローの場合はuuidは使いまわす
          const currentFlow = others ? _.find(this.form.partialInfo, {otherTradingId: flow.otherTradingId, tradingFlowId: flow.tradingFlowId}) : null;
          // uuidを初期値に仕込む
          return {
            ...flow,
            uuid: currentFlow ? currentFlow.uuid : generateUuid(),
          };
        });

        partialInfo = _.map(partialInfo, flow => {
          return {
            ...flow,
            entityStatus: _.map(flow.entityStatus, entity => {
              return {
                ...entity,
                prevEntityStatus: _.map(entity.prevEntityStatus, s => {
                  return {
                    ...s,
                    prevUuid: _.get(_.find(partialInfo, f => {
                      return (this.tradingId === s.prevTradingId || f.otherTradingId === s.prevTradingId) && f.tradingFlowId === s.prevTradingFlowId;
                    }), 'uuid'),
                  };
                }),
              }
            }),
            subordinateInfo: {
              ...flow.subordinateInfo,
              parentFlowInfo: _.map(flow.subordinateInfo.parentFlowInfo, parentFlowInfo => {
                return {
                  ...parentFlowInfo,
                  parentUuid: parentFlowInfo.parentFlowId ? _.get(_.find(partialInfo, f => {
                      return (this.tradingId === parentFlowInfo.parentTradingId || f.otherTradingId ===parentFlowInfo.parentTradingId) && f.tradingFlowId === parentFlowInfo.parentFlowId;
                    }), 'uuid') : undefined,
                }
              }),

            }
          };
        });

        this.parentGoodsAssignment = res.parentGoodsAssignment;

        if (others) {
          // フローを追加したとき
          this.form.partialInfo = [..._.reject(this.form.partialInfo, 'otherTradingFlg'), ...partialInfo];
          this.original = [..._.reject(this.original, 'otherTradingFlg'), ...partialInfo];
        } else {
          this.form.partialInfo = _.cloneDeep(partialInfo);
          this.original = _.cloneDeep(partialInfo);
          this.initialOthers =  _.map(_.filter(this.form.partialInfo, {otherTradingFlg: OTHER_TRADING_FLG.ON}), 'uuid');
        }

        if (this.apiUseCode === PARTIAL_API_USE_CODE.COMBINE && !others) {
          // 混載の初回は選択したフローまでスクロールする
          this.$nextTick(() => {
            const targetEl = this.$refs.flow.querySelector('.right .entities.selected .entity');
            if (targetEl) {
              targetEl.scrollIntoView({
                behavior: 'auto',
                block: 'start',
              });
            }
          });
        }
      })
      .catch(err => {
        // 追加の別フローがある場合は追加フローから削除
        if (others) {
          this.addedFlows = _.reject(this.addedFlows, id => others.includes(id));
        }
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    // 指定したオブジェクトの中身を一階層上に展開
    flatten(ary, target) {
      return _.map(ary, o => {
        return {..._.omit(o, [target]), ...o.linkageInfo};
      });
    },
    // parentUuidが一致するかを返却します
    isParentUuidMatch(flow, parentUuid) {
      return _.some(flow.subordinateInfo.parentFlowInfo, {parentUuid: parentUuid});
    },
    // 兄弟フローを返却します
    getBrother(flow, partialInfo = this.form.partialInfo) {
      return _.filter(partialInfo, f => {
        return f.uuid !== flow.uuid && _.some(f.subordinateInfo.parentFlowInfo,  info => this.isParentUuidMatch(flow, info.parentUuid));
      });
    },
    // 分納元エンティティの子を返却します
    getChild(flow, partialInfo = this.form.partialInfo) {
      return _.filter(partialInfo, f => {
        return this.isParentUuidMatch(f, flow.uuid);
      });
    },
    // エンティティの並び順を返却します
    getEntityOrder(entityTypeCd) {
      return _.findIndex(this.entityNames, {code: entityTypeCd});
    },
    // 子フローのentityTypeCdを返却します
    getUniqEntities(uuid, ary) {
      const children = _.filter(this.form.partialInfo, f => {
        return this.isParentUuidMatch(f, uuid);
      });
      if (_.isEmpty(children)) {
        return ary;
      }
      const lastChild = _.last(children);
      let uniqEntities = _.map(_.flattenDeep(_.get(lastChild, 'entityStatus')), entity => {
        return {
          entityTypeCd: entity.entityTypeCd,
          copyEntityId: entity.entityId || entity.copyEntityId,
          canPartialShipmentFlg: entity.canPartialShipmentFlg,
        };
      });

      ary = ary.concat(uniqEntities);
      // 再帰的に子フローを探す
      return ary.concat(this.getUniqEntities(lastChild.uuid, ary));
    },
    // 子孫フローのエンティティ情報を返却します
    getUniqEntityNames(flow, entityTypeCd) {
      const parentEntities = _.map(flow.entityStatus, entity => {
        return {
          entityTypeCd: entity.entityTypeCd,
          copyEntityId: entity.entityId || entity.copyEntityId,
          canPartialShipmentFlg: entity.canPartialShipmentFlg,
        };
      });
      const uniqEntities = _.uniqBy(this.getUniqEntities(flow.uuid, parentEntities), 'entityTypeCd');
      const dropIndex = _.findIndex(uniqEntities, n => n.entityTypeCd === entityTypeCd);
      const droppedEntities = _.drop(uniqEntities, dropIndex);
      const uniqEntityNames = _.filter(_.map(entityNames, o => o), entity => {
        return _.some(droppedEntities, {entityTypeCd: entity.code});
      });
      return {uniqEntityNames, droppedEntities};
    },
    // エンティティのひな型を返却します
    createEntityTemplate(uniqEntityNames) {
      return _.map(uniqEntityNames, (entity, index) => {
        return {
          canPartialShipmentFlg: CAN_PARTIAL_SHIPMENT_FLG.ON,
          entityTypeCd: entity.code,
          entityProgressStatusCd: 0,
          prevEntityStatus: index ? [
            {
              prevEntityTypeCd: uniqEntityNames[index - 1].code,
            }
          ] : [{}],
        }
      });
    },
    // フロー挿入位置を返却します
    getInsertIndex(uuid, entityOrder) {
      // 追加するフローの最後の兄弟フローかつ、最初のエンティティの並び順が追加するフローのエンティティ以上のフローのINDEX
      const index = _.findLastIndex(this.form.partialInfo, o => {
        return this.isParentUuidMatch(o, uuid) && entityOrder <= this.getEntityOrder(o.entityStatus[0].entityTypeCd);
      });
      // 兄弟フローが存在しなければ-1を返却
      if (index < 0) {
        return index;
      }
      // 再帰的に兄弟フローの子フローを探す
      return Math.max(index, this.getInsertIndex(this.form.partialInfo[index].uuid, entityOrder));
    },
    // エンティティがフローの先頭かどうかを返却します
    isFirstEntity(flow, entity) {
      return _.findIndex(flow.entityStatus, {entityTypeCd: entity.entityTypeCd}) === 0;
    },
    // フローを分納します
    partial(entity, flowIndex, whenCombine = false) {
      this.backup = _.cloneDeep(this.form.partialInfo);
      this.backupOriginal = _.cloneDeep(this.original);
      // 複製元のフロー
      const copySource = this.form.partialInfo[flowIndex];
      // 分納元のエンティティ分割位置
      const entityIndex = _.findIndex(copySource.entityStatus, {entityTypeCd: entity.entityTypeCd});
      // 混載時の主混載元フロー
      let mainCombineFlow;
      if (copySource.subordinateInfo.combineFlg === COMBINE_FLOW_FLG.ON && !entityIndex) {
        const mainCombineFlowUuid = _.get(_.find(copySource.subordinateInfo.parentFlowInfo, info => info.mainCombineFlg === MAIN_COMBINE_FLG.ON), 'parentUuid');
        mainCombineFlow = _.find(this.form.partialInfo, {uuid: mainCombineFlowUuid});
      }
      // 分納元のフロー
      // 主混載元があれば主混載元を分納元とする
      const parent = mainCombineFlow ? mainCombineFlow : !entityIndex ? _.find(this.form.partialInfo, info => this.isParentUuidMatch(copySource, info.uuid)) : copySource;
      // 分納元の子フロー
      const children = this.getChild(copySource);

      // 分納元の兄弟フロー
      const brother = this.getBrother(copySource);

      // 初回分納か
      const isFirst = !entity.prevEntityStatus[0].prevUuid || entity.prevEntityStatus[0].prevUuid === copySource.uuid;

      // 後ろからの分納か
      const isBackward = isFirst && copySource.subordinateInfo.parentFlg === SUBORDINATE_FLOW_FLG.ON;


      let newEntityStatus;
      let copyEntityIds = []; // コピー元のエンティティID

      if (isFirst) {
        // 初回分納の時
        // 分納元のエンティティを切り捨て、後続のひな型を用意
        const tail = copySource.entityStatus.splice(entityIndex);
        const entityNames = _.map(tail, entity => {
          return _.find(this.entityNames, {code: entity.entityTypeCd});
        });
        copyEntityIds = _.map(tail, entity => {
          return {
            entityTypeCd: entity.entityTypeCd,
            copyEntityId: entity.entityId || entity.copyEntityId,
            canPartialShipmentFlg: entity.canPartialShipmentFlg,
          };
        });
        newEntityStatus = this.createEntityTemplate(entityNames);
      } else {
        // 後続のひな型を用意
        const uniqEntities = this.getUniqEntityNames(copySource, entity.entityTypeCd);
        copyEntityIds = uniqEntities.droppedEntities;
        newEntityStatus = this.createEntityTemplate(uniqEntities.uniqEntityNames);
      }

      // 分納元の親フラグをON
      parent.subordinateInfo.parentFlg = SUBORDINATE_FLOW_FLG.ON;

      // コピー元フローを更新
      this.$set(this.form.partialInfo, flowIndex, copySource);
      // 子フロー
      const newFlow = _.cloneDeep(copySource);
      newFlow.copyTradingFlowId = copySource.tradingFlowId || copySource.copyTradingFlowId;
      newFlow.copyUuid = copySource.tradingFlowId ? copySource.uuid : copySource.copyUuid;
      newFlow.tradingFlowId = null;
      newFlow.tradingManageName = null;
      newFlow.goodsAssignmentProhibited = 0;
      newFlow.deletableFlg = DELETE_ABLE_FLG.OK;
      newFlow.uuid = generateUuid();

      // 兄弟フローに混載フローがいるか
      const hasCombinedBrother = copySource.subordinateInfo.combineFlg === COMBINE_FLOW_FLG.ON || _.some(brother, flow => {
        return flow.subordinateInfo.combineFlg === COMBINE_FLOW_FLG.ON;
      });

      // 商品情報 初回分納時は親フローの値、2回目以降は残数
      const parentGoodsAssignment = isFirst ? _.last(parent.entityStatus).goodsAssignment : _.map(_.last(parent.entityStatus).goodsAssignment, goods => {
        const tmpGoods = {
          ...goods,
          // 分納元の兄弟フローに混載フローがある場合は親フローのgoodsLineSeq, goodsLineIdの値をprevに指定
          prevGoodsLineSeq: hasCombinedBrother ? goods.goodsLineSeq : goods.prevGoodsLineSeq,
          prevGoodsLineId: hasCombinedBrother ? goods.goodsLineId : goods.prevGoodsLineId,
        };
        return {
          ...tmpGoods,
          quantity1: Math.max(this.getRemainingQuantityForPartial(tmpGoods, copySource, parent), 0),
        };
      });

      const totalQuantity = Math.max(new BigNumber(_.sumBy(parentGoodsAssignment, 'quantity1')).dp(4).toNumber() || 0, 0);

      newFlow.entityStatus = _.map(newEntityStatus, (o, index) => {
        return {
          ...o,
          copyEntityId: _.get(_.find(copyEntityIds, {entityTypeCd: o.entityTypeCd}), 'copyEntityId'),
          totalAmountInfo: {
            ..._.last(parent.entityStatus).totalAmountInfo,
            totalQuantity: totalQuantity,
          },
          goodsAssignment: _.map(parentGoodsAssignment, goods => {
            return {
              ...goods,
              copyGoodsLineSeq: goods.goodsLineSeq,
              copyGoodsLineId: goods.goodsLineId,
            }
          }),
          prevEntityStatus: _.map(o.prevEntityStatus, (s, i) => {
            return {
              ...s,
              prevEntityTypeCd: index ? s.prevEntityTypeCd : entity.prevEntityStatus[i].prevEntityTypeCd,
              prevEntityId: index ? undefined : entity.prevEntityStatus[i].prevEntityId,
              prevUuid:  index ? newFlow.uuid : parent.uuid,
              prevTradingFlowId: index ? newFlow.tradingFlowId : parent.tradingFlowId,
            }
          }),
          canPartialShipmentFlg: _.get(_.find(copyEntityIds, {entityTypeCd: o.entityTypeCd}), 'canPartialShipmentFlg'),
        }
      });

      newFlow.subordinateInfo = {
        parentFlg: null,
        subFlg: SUBORDINATE_FLOW_FLG.ON,
        combineFlg: COMBINE_FLOW_FLG.OFF,
        parentFlowInfo: [
          {
            parentTradingId: parent.otherTradingId || this.tradingId,
            parentFlowId: parent.tradingFlowId,
            parentUuid: parent.uuid,
            mainCombineFlg: whenCombine ? MAIN_COMBINE_FLG.ON : MAIN_COMBINE_FLG.OFF,
          }
        ],
      };

      // 子フローの適切な挿入位置
      let insertIndex;

      // 分納元フローが混載元フローかつ主混載元ではないか
      const hasCombinedFlow = children.length && _.some(children, child => {
        if (child.subordinateInfo.combineFlg === COMBINE_FLOW_FLG.OFF) {
          return false;
        }
        const mainCombineFlow = _.find(child.subordinateInfo.parentFlowInfo, info => info.mainCombineFlg === MAIN_COMBINE_FLG.ON);
        return mainCombineFlow && mainCombineFlow.parentUuid !== copySource.uuid;
      });


      if (hasCombinedFlow) {
        // 分納元フローが混載元フローかつ主混載元ではない場合
        insertIndex = this.getInsertIndex(parent.uuid, this.getEntityOrder(newFlow.entityStatus[0].entityTypeCd));
      } else {
        insertIndex = this.getInsertIndex(copySource.uuid, this.getEntityOrder(newFlow.entityStatus[0].entityTypeCd));
      }

      insertIndex = insertIndex < 0 ? (flowIndex + 1) : (insertIndex + 1);

      // 後ろからの分納の時
      if (isBackward) {
        insertIndex = flowIndex + 1;
        newFlow.subordinateInfo.parentFlg = SUBORDINATE_FLOW_FLG.ON;
        _.forEach(children, child => {
          const parentFlowInfo = _.map(child.subordinateInfo.parentFlowInfo, info => {
            if (info.parentUuid === copySource.uuid) {
              return {
                parentTradingId: newFlow.otherTradingId || this.tradingId,
                parentFlowId: newFlow.tradingFlowId,
                parentUuid: newFlow.uuid,
                mainCombineFlg: info.mainCombineFlg,
              };
            }
            return info;
          });
          child.subordinateInfo = {
            ...child.subordinateInfo,
            parentFlowInfo: parentFlowInfo,
          };
          child.copyTradingFlowId = newFlow.copyTradingFlowId;
          child.copyUuid = newFlow.copyUuid;
          const targetEntityStatus = _.find(child.entityStatus[0].prevEntityStatus, {prevUuid: copySource.uuid}) || child.entityStatus[0].prevEntityStatus[0];
          targetEntityStatus.prevUuid = newFlow.uuid,
          targetEntityStatus.prevTradingFlowId = newFlow.tradingFlowId;
          const index = _.findIndex(this.original, {uuid: child.uuid});
          this.original.splice(index, 1, child);
        });
      }
      this.form.partialInfo.splice(insertIndex, 0, newFlow);
      // オリジナルに追加
      this.original.push(_.cloneDeep(newFlow));
      // 追加したフローをハイライト
      this.newFlowUuid = newFlow.uuid;
      // 追加したフローが画面内にいなければスクロール
      this.$nextTick(() => {
        const targetEl = this.$refs.flow.querySelector('.right .entities.new');
          targetEl.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      });

      if (!whenCombine) {
        // 混載の時以外はドロワーを表示
        this.showPartialDetail(newFlow.entityStatus[0], insertIndex);
        // 分納取り消し可能に
        this.cancelable = true;
        this.hasDiff = true;
      }

      return newFlow;
    },
    deleteFlowConfirm(flow) {
      this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete this data?')
        .then(() => {
          this.deleteFlow(flow);
        })
        .catch(() => {});
    },
    // サブフローを削除します
    deleteFlow(flow, force) {
      const flowId = flow.tradingFlowId;

      // 削除するフローの兄弟フローが存在するか（親フローも親エンティティも同じ
      const hasBrother = this.getBrother(flow).length;

      // 削除するフローの子フロー
      const children = this.getChild(flow);
      if (children.length) {
        // 子フローが存在しても削除できるのは混載時のみ
        // 再帰呼び出し
        _.forEach(children, flow => {
          this.deleteFlow(flow, true);
        });
      }

      if (this.checkedCombineFlows.includes(flow.uuid)) {
        // 混載のチェックを付けたフローの場合はチェックを外す
        const deleteCombineFlowIndex = _.findIndex(this.checkedCombineFlows, uuid => uuid === flow.uuid);
        this.checkedCombineFlows.splice(deleteCombineFlowIndex, 1);
      }

      // オリジナルからフローを削除
      const deleteOriginalFlowIndex = _.findIndex(this.original, {uuid: flow.uuid});
      if (deleteOriginalFlowIndex >= 0) {
        this.original.splice(deleteOriginalFlowIndex, 1);
      }

      // 別取引で場合、混載時以外は追加フローから削除
      if (flow.otherTradingFlg === OTHER_TRADING_FLG.ON && !this.isPreviewMode) {
        const deleteId = `${flow.otherTradingId}-${flow.tradingFlowId}`;
        this.addedFlows = _.reject(this.addedFlows, id => id === deleteId);
      }

      // 削除するフローのindex
      const index = _.findIndex(this.form.partialInfo, {uuid: flow.uuid});

      if (force || hasBrother) {
        // 兄弟フローが存在するとき
        // partialInfoからフローを削除
        this.form.partialInfo.splice(index, 1);
      } else {
        // 兄弟フローが存在しないとき

        // 親フローに補完する後続エンティティのひな型
        const newEntityStatus = _.cloneDeep(flow.entityStatus);
        // 親フローのUUID
        const parentFlowUuid = flow.subordinateInfo.parentFlowInfo[0].parentUuid;

        // オリジナルの親フローのindex
        const originalParentFlowIndex = _.findLastIndex(this.original, f => {
          return parentFlowUuid === f.uuid;
        });

        // partialInfoからフローを削除
        this.form.partialInfo.splice(index, 1);

        // 親フローのindex
        const parentFlowIndex = _.findLastIndex(this.form.partialInfo, {uuid: parentFlowUuid});

        let newParentFlow;
        // オリジナルの親フロー
        if (originalParentFlowIndex >= 0 && flowId) {
          // オリジナルの親フローが存在するとき
          newParentFlow = _.cloneDeep(this.original[originalParentFlowIndex]);
          // フロー名を変更済みのものに
          newParentFlow.tradingManageName = this.form.partialInfo[parentFlowIndex].tradingManageName;
        } else {
          // オリジナルの親フローが存在しないとき
          newParentFlow = _.cloneDeep(this.form.partialInfo[parentFlowIndex]);
        }

        // 親フラグをnullに
        newParentFlow.subordinateInfo.parentFlg = null;

        // 親フローの最後のエンティティ
        const lastEntity = _.last(newParentFlow.entityStatus);

        // 親フローに後続エンティティを補完する
        newParentFlow.entityStatus = newParentFlow.entityStatus.concat(
          _.map(newEntityStatus, o => {
            return {
              ...o,
              totalAmountInfo: lastEntity.totalAmountInfo,
              goodsAssignment: lastEntity.goodsAssignment,
              prevEntityStatus: _.map(o.prevEntityStatus, s => {
                return {
                  ...s,
                  prevUuid: newParentFlow.uuid,
                  prevTradingFlowId: newParentFlow.tradingFlowId,
                }
              })
            };
          })
        );

        if (originalParentFlowIndex >= 0) {
          // オリジナルにセット
          this.$set(this.original, originalParentFlowIndex, newParentFlow);
        }

        // partialInfoにセット
        this.$set(this.form.partialInfo, parentFlowIndex, _.cloneDeep(newParentFlow));
      }

      this.hasDiff = true;
    },
    // 分納情報を登録します
    register() {
      this.$store.commit('START_PROCESS');
      // flowLineNoをリクエストに仕込む
      let partialInfo = _.map(_.cloneDeep(this.form.partialInfo), (flow, index) => {
        return {
          ...flow,
          flowLineNo: index,
        };
      });

      partialInfo = _.map(partialInfo, flow => {
        return {
          ...flow,
          entityStatus: _.map(flow.entityStatus, o => {
            return {
              ...o,
              prevEntityStatus: o.prevEntityStatus,
            };
          }),
          subordinateInfo: {
            ...flow.subordinateInfo,
            parentFlowInfo: _.map(flow.subordinateInfo.parentFlowInfo, parentFlowInfo => {
              return {
                ...parentFlowInfo,
                parentFlowLineNo: parentFlowInfo.parentUuid ? _.get(_.find(partialInfo, f => {
                  return f.uuid === parentFlowInfo.parentUuid;
                }), 'flowLineNo') : undefined,
              }
            }),
          }
        };
      });

      // bff_td_14 分納管理登録
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'post_/v1/tradings/{companyId}/manages/partial',
          path: {
            companyId: this.$store.state.companyId,
          },
          query: {
            apiUseCode: this.apiUseCode,
            others: this.apiUseCode === PARTIAL_API_USE_CODE.COMBINE ? this.addedFlows : undefined,
          }
        },
        data: {
          tradingId: this.tradingId,
          fromExternal: EXTERNAL_USER_FLG_VARIABLES.OFF, // 外部連携フラグ
          partialInfo: partialInfo,
        }
      };

      $api.request(params)
      .then(res => {
        this.$store.commit('END_PROCESS');
        this.$emit('partial-registered', res);
        setTimeout(() => {
          this.$store.dispatch('SHOW_COMPLETED');
        }, 500);
      })
      .catch(err => {
        this.$store.commit('END_PROCESS');
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    // 分納モードを終了します
    cancel() {
      this.$emit('cancel');this.parentGood
    },
    // 編集モードに切り替えます
    edit() {
      this.viewOnly = false;
    },
    // ドロワー ----------------------------------------------------------------------------
    // 同一商品かを返却します
    isEqualGoods(goods, o) {
      if (goods.copyGoodsLineSeq) {
        // 今回コピーされた商品の場合（goodsLineSeq未採番）
        return o.goodsLineId === goods.copyGoodsLineId && o.goodsLineSeq === goods.copyGoodsLineSeq;
      }

      return (o.goodsLineId === goods.goodsLineId && o.goodsLineSeq === goods.goodsLineSeq) || (o.goodsLineId === goods.prevGoodsLineId && o.goodsLineSeq === goods.prevGoodsLineSeq) || (o.prevGoodsLineId === goods.goodsLineId && o.prevGoodsLineSeq === goods.goodsLineSeq);
    },
    // 親商品群を返却します
    getParentGoodsAssignment(selectFlowIndex, partialInfo = this.form.partialInfo) {
      if (!_.isNumber(selectFlowIndex)) {
        return [];
      }
      const selectFlow = partialInfo[selectFlowIndex];
      if (selectFlow.subordinateInfo.subFlg === SUBORDINATE_FLOW_FLG.OFF) {
        // 親がいないフローの場合
        if (selectFlow.otherTradingFlg === OTHER_TRADING_FLG.ON) {
          return _.get(_.find(this.parentGoodsAssignment, {parentTradingId: selectFlow.otherTradingId, parentTradingFlowId: selectFlow.tradingFlowId}), 'goodsInfo', []);
        } else {
          return _.get(_.find(this.parentGoodsAssignment, {parentTradingId: this.tradingId, parentTradingFlowId: selectFlow.tradingFlowId}), 'goodsInfo', []);
        }
      } else {
        const parentFlows = _.map(selectFlow.subordinateInfo.parentFlowInfo, info => {
          return _.find(partialInfo, {uuid: info.parentUuid});
        });

        const parentEntities = _.map(parentFlows, parentFlow => _.last(parentFlow.entityStatus));
        return _.reduce(parentEntities, (ary, entity) => {
          ary = ary.concat(entity.goodsAssignment);
          return ary;
        }, []);
      }
    },
    // 商品数量ドロワーを表示します
    showPartialDetail(entity, flowIndex) {
      this.selectEntity = entity;
      this.selectFlow = this.form.partialInfo[flowIndex];
      this.selectFlowIndex = flowIndex;
      this.originGoodsAssignment = _.cloneDeep(entity.goodsAssignment);
      this.form.cloneGoodsAssignment = _.cloneDeep(entity.goodsAssignment);
      this.isShowDetail = true;
    },
    // 商品の数量を確定します
    commitDrawer() {
      this.selectFlow.entityStatus = _.map(this.selectFlow.entityStatus, entity => {
        const goodsAssignment = _.map(this.form.cloneGoodsAssignment, goods => {
          return {
            ...goods,
            quantity1: _.isNumber(goods.quantity1) ? goods.quantity1 : 0,
          };
        });
        const totalQuantity = new BigNumber(_.sumBy(goodsAssignment, 'quantity1')).dp(4).toNumber() || 0;
        return {
          ...entity,
          goodsAssignment: goodsAssignment,
          totalAmountInfo: {
            ...entity.totalAmountInfo,
            totalQuantity: totalQuantity,
          }
        };
      });

      // フローのデータを更新
      this.$set(this.form.partialInfo, this.selectFlowIndex, _.cloneDeep(this.selectFlow));
      this.isShowDetail = false;
      this.selectEntity = null;
      this.selectFlow = null;
      this.selectFlowIndex = null;
      this.form.cloneGoodsAssignment = [];
      this.originGoodsAssignment = [];
      this.hasDiff = true;
      this.cancelable = false;
      this.newFlowUuid = null;
    },
    // 商品を削除します
    deleteGoods(index) {
      this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete this data?')
        .then(() => {
          this.selectGoodsIndex = null;
          this.form.cloneGoodsAssignment.splice(index, 1);
        })
        .catch(() => {});
    },
    // 商品を追加します
    onGoodsSelect(val) {
      this.form.cloneGoodsAssignment.push(_.cloneDeep(_.find(this.addableGoods, {originGoodsLineSeq: val.goodsLineSeq, originGoodsLineId: val.originGoodsLineId})));
      this.goodsModel = null;
      this.selectGoodsIndex = null;
    },
    // 商品数量ドロワーを閉じます
    closeDrawer() {
      if (this.goodsEditable) {
        // 変更があった場合コンファーム
        this.$store.dispatch('SHOW_CONFIRM', 'Are you sure to go back? (Unsaved data shall be deleted)')
        .then(() => {
          this.isShowDetail = false;
          this.selectEntity = null;
          this.selectFlow = null;
          this.selectFlowIndex = null;
          this.form.cloneGoodsAssignment = [];
          this.originGoodsAssignment = [];
          if (this.cancelable) {
            this.form.partialInfo = this.backup;
            this.original = this.backupOriginal;
          }
          this.cancelable = false;
          this.newFlowUuid = null;
          this.backup = null;
          this.backupOriginal = null;
          this.selectGoodsIndex = null;
        })
        .catch(() => {});
      } else {
        this.cancelable = false;
        this.isShowDetail = false;
        this.selectEntity = null;
        this.selectFlow = null;
        this.selectFlowIndex = null;
        this.form.cloneGoodsAssignment = [];
        this.originGoodsAssignment = [];
        this.newFlowUuid = null;
        this.backup = null;
        this.backupOriginal = null;
        this.selectGoodsIndex = null;
      }
    },
    // 同じ商品の数量の合計値を返却します
    sumGoods(goodsAssignment, goods) {
      return _.sumBy(_.filter(goodsAssignment, o => {
        return this.isEqualGoods(goods, o);
      }), 'quantity1') || 0;
    },
    sumBrotherGoods(goodsAssignment, goods) {
      return _.sumBy(_.filter(goodsAssignment, o => {
        return (o.goodsLineId === goods.goodsLineId && o.goodsLineSeq === goods.goodsLineSeq) || (o.goodsLineId === goods.prevGoodsLineId && o.goodsLineSeq === goods.prevGoodsLineSeq) || (o.prevGoodsLineId === goods.goodsLineId && o.prevGoodsLineSeq === goods.goodsLineSeq);
      }), 'quantity1') || 0;
    },
    // 兄弟フローの残商品数の合計を返却します
    getBrotherRemainingQuantity(goods, selectFlow, partialInfo = this.form.partialInfo) {
      const brother = this.getBrother(selectFlow, partialInfo);
      const sum = _.sumBy(brother, flow => {
        const goodsAssignment = _.get(_.head(flow.entityStatus), 'goodsAssignment');
        return this.sumBrotherGoods(goodsAssignment, {
          ...goods,
          prevGoodsLineId: goods.oldPrevGoodsLineId || goods.prevGoodsLineId,
          prevGoodsLineSeq: goods.oldPrevGoodsLineSeq || goods.prevGoodsLineSeq,
        });
      }) || 0;
      return sum;
    },
    // 分納用の商品の残数量を返却します
    getRemainingQuantityForPartial(goods, selectFlow, parentFlow, partialInfo = this.form.partialInfo) {
      const selectGoodsTotal = this.sumGoods(_.last(selectFlow.entityStatus).goodsAssignment, goods);
      const total = this.sumGoods(_.last(parentFlow.entityStatus).goodsAssignment, goods);
      const sum = this.getBrotherRemainingQuantity(goods, selectFlow, partialInfo);

      return new BigNumber(total - sum - selectGoodsTotal).dp(4).toNumber();
    },
    // 残数量を返却します
    calcRemainingQuantity(goods, selectFlowIndex, entityGoodsAssignment, partialInfo = this.form.partialInfo) {
      const selectFlow = partialInfo[selectFlowIndex];
      const total = this.sumGoods(this.getParentGoodsAssignment(selectFlowIndex, partialInfo), goods);
      // 混載プレビューモード時の混載先フローのみgoodsAssignment内の同じ商品を除外
      const selectGoodsTotal = this.isPreviewMode && selectFlow.uuid === this.selectedFlowUuid ? this.sumGoods(entityGoodsAssignment, goods) - goods.quantity1 : 0;
      const sum = this.getBrotherRemainingQuantity(goods, selectFlow, partialInfo);

      return {total: total - sum - selectGoodsTotal, result: total - sum - selectGoodsTotal - goods.quantity1};
    },
    showSummary() {
      console.log(this.getGoodsAssignmentList(1))
    },
    // 各フローの商品数量を配列で返却します
    getGoodsAssignmentList(entityTypeCd, partialInfo = this.form.partialInfo) {
      return _.map(partialInfo, (flow, index) => {
        const entity = _.find(flow.entityStatus, {entityTypeCd: entityTypeCd});
        const goodsAssignment = entity ? _.map(entity.goodsAssignment, goods => {
          const remainingQuantity = this.calcRemainingQuantity(goods, index, entity.goodsAssignment, partialInfo);
          return {
            ...goods,
            quantity1: goods.quantity1 || 0,
            total: remainingQuantity.total,
            result: remainingQuantity.result,
          };
        }) : [];

        return {
          otherTradingFlg: flow.otherTradingFlg,
          tradingId: flow.otherTradingFlg === OTHER_TRADING_FLG.ON ? flow.otherTradingId : this.tradingId,
          tradingFlowId: flow.tradingFlowId,
          uuid: flow.uuid,
          tradingName: flow.otherTradingFlg === OTHER_TRADING_FLG.ON ? flow.otherTradingName : this.tradingName,
          tradingManageName: flow.tradingManageName,
          subordinateInfo: flow.subordinateInfo,
          goodsAssignment: goodsAssignment,
          quantity1: _.sumBy(goodsAssignment, 'quantity1') || 0,
          total: _.sumBy(goodsAssignment, 'total') || 0,
          result: _.sumBy(goodsAssignment, 'result') || 0,
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
  .partial_view {
    z-index: 101;
    position: fixed;
    top: 82px;
    left: 0;
    bottom: 0;
    min-width: 100%;
    width: 100%;
    background: $color_gray_300;
    padding: 0 41px;
    overflow-x: auto;

    >.buttons {
      position: fixed;
      bottom: auto;
      top: 21px;
      padding-bottom: 0;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 38px;
    padding-right: 41px;
  }

  .flow_list_container {
    position: absolute;
    top: 0;
    left: 41px;
    right: 41px;
    bottom: 41px;
    overflow: hidden;
    // overflow-y: auto;
    background: $color_white;
    // filter: drop-shadow(-4px -4px 8px rgba(255, 255, 255, 0.5)) drop-shadow(4px 4px 8px rgba(170, 170, 204, 0.25)) drop-shadow(0px 8px 12px rgba(170, 170, 204, 0.5)) drop-shadow(-3px -3px 8px #FCFCFF);
    box-shadow: 0px 4px 8px rgba(170, 170, 204, 0.5);
    border-radius: 8px;

    .flow_area {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      background: $color_gray_100;

      .header {
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        height: 36px;
        display: flex;
        color: $color_gray_800;
        font-size: 12px;
        line-height: 18px;
        background: $color_white;
        z-index: 10;
        border-bottom: 1px solid $color_gray_400;

        div {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 100%;
          flex: 1;
        }

        .flow_label {
          width: 279px;
          height: 100%;
          flex: initial;
          border-right: 1px solid $color_gray_400;
        }
      }

      .flow {
        position: relative;
        width: 100%;
        min-width: 100%;
        height: calc(100% - 37px);
        overflow: auto;

        .left {
          position: sticky;
          top: 0;
          left: 0;
          width: 279px;
          height: 100%;
          z-index: 2;
        }

        .right {
          position: absolute;
          top: 0;
          left: 279px;
          min-width: calc(100% - 279px);
          min-height: 100%;
          // overflow-y: visible;
          // overflow-x: auto;
        }


        .flow_label {
          position: relative;
          width: 279px;
          height: 111px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-shrink: 0;
          border-bottom: 1px solid $color_gray_300;
          background: #ffffff;
          border-right: 1px solid $color_gray_400;
          padding: 0 12px;

          &.other-trading {
            background: $color_gray_100;
          }

          .main_label {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background: #8AC7FF;
            border-radius: 8px;
            height: 16px;
            align-self: flex-start;
            font-weight: bold;
            font-size: 10px;
            line-height: 14px;
            color: #ffffff;
            padding: 0 8px;
            margin-bottom: 4px;
            width: 67px;
          }

          .other_label {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background: $color_gray_800;
            border-radius: 8px;
            height: 16px;
            align-self: flex-start;
            font-weight: bold;
            font-size: 10px;
            line-height: 14px;
            color: #ffffff;
            margin-bottom: 4px;
            width: 43px;
          }

          .between {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            height: 40px;
            margin-top: 7px;

            .button_detail {
              margin-bottom: 4px;
              flex-shrink: 0;
              transform: translateZ(0);
            }
          }

          .trading_name {
            display: inline-block;
            font-size: 14px;
            line-height: 20px;
            color: $color_black;
            font-weight: bold;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 210px;
          }

          .flow_name {
            font-size: 14px;
            line-height: 20px;
            color: $color_black;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .el-form-item {
            margin-bottom: 0;

            ::v-deep .el-input__inner {
              height: 28px;
              line-height: 28px;
              font-size: 14px;
              color: $color_gray_800;
            }

            ::v-deep .el-form-item__label {
              display: none;
            }
          }

          .close_times {
            position: absolute;
            display: block;
            width: 16px;
            height: 16px;
            top: 3px;
            right: 5px;
            cursor: pointer;
            &:hover {
              opacity: .5;
            }
          }
        }

        // &:last-child {
        //   .flow_label, .entities {
        //     border-bottom: 0;
        //   }
        // }

        .entities {
          display: flex;
          height: 111px;
          padding: 0 25px 0 13px;
          flex-shrink: 0;
          border-bottom: 1px solid $color_gray_400;
          min-width: 100%;

          &.new {
            background: rgba(103, 239, 10, 0.2);
          }

          &.other-trading {
            background: $color_gray_300;
          }

          .entity {
            position: relative;
            width: 169px;
            padding: 15px 0;
            flex-shrink: 0;
            &:last-child {
              width: 117px!important;

              .flow_line {
                right: 121px!important;
              }
            }
          }
        }
      }
    }
  }
</style>

<style lang="scss">
  .partial_detail {
    overflow-x: hidden;

    .el-drawer__header {
      display: none;
    }

    .el-drawer__body {
      padding: 23px 19px 120px;

      .buttons {
        padding-right: 19px;
        padding-top: 24px;
        background: white;
      }
    }

    h2 {
      font-size: 16px;
      margin: 0 0 12px;
      padding-bottom: 15px;
      border-bottom: 1px solid #EDEDF4;
    }

    div.table_scroller {
      max-height: calc(100% - 130px);
      overflow: hidden;
      overflow-y: scroll;
    }

    table.goods_table {
      table-layout: fixed;
      min-width: 100%;
      border-collapse: collapse;
      background: #FFFFFF;
      font-size: 12px;
      color: $color_black;

      th {
        font-size: 12px;
        color: white;
        text-align: center;
        font-weight: normal;
        border-left: 1px solid $color_gray_300;
        border-right: 1px solid $color_gray_300;
        background: $color_dark_blue;
        padding: 4px 5px;
      }

      td {
        padding: 0 5px;
        border-left: 1px solid $color_gray_300;
        border-right: 1px solid $color_gray_300;
        border-bottom: 1px solid $color_gray_300;
        line-height: 29px;
        vertical-align: top;
      }

      td.quantity {
        text-align: right;

        &.input_cell {
          padding: 0;
        }

        &.minus {
          color: $color_warning;
        }
      }

      td.delete_cell {
        border-left: 0;
        padding:0;
        vertical-align:middle;

        img {
          display: block;
          margin: 0 auto;
          width: 20px;
          cursor: pointer;
          opacity: 0;
        }
      }


      tr:hover td {
        background-color: $color_gray_background;

        &.delete_cell img {
          opacity: .8;
        }
      }

      tr.selected td {
        background-color: $color_selected_row;
      }

      tr.selected:hover td {
        background-color: #ddf8ca;

        .el-form-item .el-input-number.is-without-controls .el-input__inner:focus {
          background: #ddf8ca!important;
        }
      }

      .el-form-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        margin: 0;

        .el-form-item__content {
          line-height: 29px;
          font-size: 12px;
          background: transparent;
        }

        .el-form-item__label {
          text-align: left;
          float: none;
          font-size: 12px;
          line-height: 20px;
          color: $color_black;
        }

        .el-input-number {
          width: 100%;
          height: 29px;
          line-height: 29px;
        }

        .el-input-number.is-without-controls .el-input__inner {
          display: block;
          height: 29px;
          padding: 0 4px;
          text-align: right;
          font-size: 12px;
          line-height: 29px;
          color: $color_gray_800;
          border-radius: 0;
          background: transparent;
          box-shadow: none;

          &:focus {
            background: #e1fcce!important;
          }

          &:not(:focus) {
            border: transparent;
          }
        }

        &:before, &:after {
          content: none;
        }

        .el-form-item__error {
          // width: 288px;
          left: auto;
          right: 0;
          text-align: left;
          position: relative;
          font-size: 10px;
          padding: 0 5px;
          line-height: 1.1;
        }
      }
    }



    .goods {
      border-top: 1px solid #EDEDF4;
      padding-bottom: 12px;

      &:first-child {
        border-top: 0;
      }

      .goods_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: $color_black;
        border-bottom: 1px solid #EDEDF4;
        padding: 12px 0;

        &.empty {
          color: $color_gray_400;
          font-weight: normal;
        }

        img.close {
          display: block;
          width: 30px;
          height: 30px;
        }
      }


      .quantity, .remaining_quantity  {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        font-size: 14px;
        line-height: 20px;
        color: $color_black;

        label {
          text-align: left;
          float: none;
          font-size: 14px;
          line-height: 20px;
          color: $color_black;
        }

        span {
          text-align: right;
          padding-right: 9px;

          &.minus {
            color: $color_warning;
          }
        }
      }

      .quantity {
        padding: 12px 0;
      }
    }

    .el-select {
      width: 288px;
      margin-top: 12px;
    }

    li.el-select-dropdown__item.empty {
      color: $color_gray_600;
    }
  }

  .goods_pulldown {
    max-width: 288px;
  }
</style>
