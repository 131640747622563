 <template>
  <div class="combine_view">
    <el-form :model="form" ref="form">
      <div class="flow_list_container">
        <div class="flow_area">
          <div class="header">
            <div class="flow_label">{{$t('Label.Trading/Flow Name')}}</div>
            <div>{{$t('Label.Combine Flow')}}</div>
          </div>
          <div class="flow" ref="flow">
            <div class="left" :style="scrollStyle">
              <div v-for="(flow, index) in form.partialInfo" :key="flow.uuid + '_' + index" class="flow_label" :class="{ first: index === 0, 'other-trading': flow.otherTradingFlg === OTHER_TRADING_FLG.ON}">
                <div class="between">
                  <div>
                    <div class="label-area">
                      <span v-if="selectedFlowUuid === flow.uuid" class="combine_label">Combine</span>
                      <span v-if="isMainFlow(flow)" class="main_label">Main Flow</span>
                      <span v-if="flow.otherTradingFlg === OTHER_TRADING_FLG.ON" class="other_label">Other</span>
                    </div>
                    <el-tooltip placement="top-start" popper-class="mcu" :tabindex="-1">
                      <div slot="content">{{flow.otherTradingName || tradingName}}</div>
                      <span class="trading_name">{{flow.otherTradingName || tradingName}}</span>
                    </el-tooltip>
                  </div>
                  <el-tooltip v-if="flow.tradingFlowId && flow.flowDetailViewableFlg === DETAIL_VIEWABLE_FLG.ON" placement="top-end" popper-class="mcu" :tabindex="-1">
                    <div slot="content" v-html="sanitize($t('Label.Trading Details'))" />
                    <tw-button type="secondary" size="small" icon="detail" class="button_detail" @click="$emit('show-flow-detail', flow)" />
                  </el-tooltip>
                </div>

                <el-form-item  v-if="!viewOnly && flow.editableFlg === EDITABLE_FLG.ON" label="Flow Name" :prop="`partialInfo[${index}].tradingManageName`" :rules="flowNameRules">
                  <el-input style="width: 100%" v-model="flow.tradingManageName" placeholder="" show-word-limit maxlength="100" :tabindex="isShowDetail || isGoodsQuantityMode || dialogSelectFlowVisible || drawerShow ? '-1' : undefined" />
                </el-form-item>
                <tw-overflow-tooltip v-else class="flow_name" :content="flow.tradingManageName" />
                <img v-if="deletable(flow)" src="@/assets/images/icons/times_gray600.svg" class="close_times" @click="deleteFlowConfirm(flow)">
              </div>
            </div>
            <div class="right">
              <div v-for="(flow, index) in form.partialInfo" :key="flow.uuid + '_' + index" class="entities" :class="{ first: index === 0, new: newFlowUuid === flow.uuid, 'combine-to': selectedFlowUuid === flow.uuid, selected: !selectedFlowUuid && !flow.otherTradingFlg && flow.tradingFlowId === tradingFlowId, 'other-trading': flow.otherTradingFlg === OTHER_TRADING_FLG.ON}">
                <div class="entity" v-for="(entity, order) in entityNames" :key="entity.code + '_' + flow.uuid" :style="{width: `${entitySpanWidth}px`}">
                  <!-- エンティティカード -->
                  <tw-partial-card-item v-if="getEntity(flow, entity.code)" :item="getEntity(flow, entity.code)" :flowIndex="index" :entityOrder="order" :viewOnly="viewOnly" :isCombine="true" :class="[entity.entityId]" :isSelectedFlow="isSelectedFlow(flow)" :selectedFlowUuid="selectedFlowUuid" :selectedEntityTypeCd="selectedEntityTypeCd" :isUncombinableFlow="isUncombinableFlow(flow)" :flowUuid="flow.uuid" :isPreviewMode="isPreviewMode" :checkedCombineFlows="checkedCombineFlows" :flowRelationalFlg="flow.flowRelationalFlg" @show-partial-detail="showPartialDetail" @partial="partial" @on-check="onCombineCheck" @combine-start="combineStart" @release-main-combine-flow="releaseMainCombineFlow" />
                  <!-- 線 -->
                  <template v-if="getEntity(flow, entity.code) && prevEntity(getEntity(flow, entity.code))">
                    <tw-flow-line v-for="(prev, i) in getEntity(flow, entity.code).prevEntityStatus || []" :key="prev.prevUuid + '_' + i + '_' + index" :entity="getEntity(flow, entity.code)" :prevEntity="prev" :flowIndex="index" :flowHeight="flowHeight" :entitySpanWidth="entitySpanWidth" :cardWidth="cardWidth" :lineMargin="lineMargin" :partialInfo="form.partialInfo" />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分納モード -->
      <el-drawer :visible.sync="isShowDetail" :append-to-body="true" custom-class="partial_detail" :before-close="closeDrawer" :size="700">
        <h2>{{$t('Label.Goods Quantity')}}</h2>
        <table v-if="isShowDetail" class="goods_table" :style="{width: scrollerWidth, minWidth: scrollerWidth}" cellspacing="0" cellpadding="0">
          <colgroup>
            <col style="width:30%;">
            <col style="width:21%;">
            <col style="width:21%;">
            <col style="width:21%;">
            <col style="width:3%;">
          </colgroup>
          <tr>
            <th>{{$t('Label.Partial Goods Name')}}</th>
            <th>{{$t('Label.Remaining Qty')}}</th>
            <th>{{$t('Label.Qty')}}</th>
            <th colspan="2">{{$t('Label.Result')}}</th>
          </tr>
        </table>
        <div ref="scroller" class="table_scroller">
          <table class="goods_table" style="width: 100%; min-width: 100%" cellspacing="0" cellpadding="0">
            <colgroup>
              <col style="width:30%;">
              <col style="width:21%;">
              <col style="width:21%;">
              <col style="width:21%;">
              <col style="width:3%;">
            </colgroup>
            <!-- eslint-disable-next-line vue/require-v-for-key -->
            <tr v-for="(goods, index) in form.cloneGoodsAssignment" :class="{selected: selectGoodsIndex === index}" @click="selectGoodsIndex = index">
              <td style="white-space: nowrap">
                <tw-overflow-tooltip v-if="goods.goodsShortName" :content="goods.goodsShortName" />
              </td>
              <td class="quantity" :class="{minus: getRemainingQuantity(goods).total < 0}">{{getRemainingQuantity(goods).total | formatQuantity}}</td>
              <!-- <td class="quantity input_cell" v-if="goodsEditable">
                <el-form-item :prop="`cloneGoodsAssignment[${index}].quantity1`" :rules="quantityRules">
                  <TwInputFormatNumber v-model="goods.quantity1" />
                </el-form-item>
              </td> -->
              <td class="quantity">{{(goods.quantity1 || 0) | formatQuantity}}</td>
              <td :colspan="goodsEditable ? 1 : 2" class="quantity" style="border-right: 0" :class="{minus: getRemainingQuantity(goods).result < 0}">{{getRemainingQuantity(goods).result | formatQuantity}}</td>
              <td v-if="goodsEditable" class="delete_cell">
                <img src="@/assets/images/icons/icon_close_600.svg" class="close" @click="deleteGoods(index)">
              </td>
            </tr>
          </table>
        </div>

        <el-select v-if="goodsEditable && addableGoods.length" class="section" placeholder="Select Goods" v-model="goodsModel" popper-class="goods_pulldown" @change="onGoodsSelect">
          <el-option
            v-for="(goods) in addableGoods"
            :key="goods.goodsLineId + '_' + goods.goodsLineSeq"
            :label="goods.goodsShortName || ' '"
            :value="goods">
          </el-option>
        </el-select>
        <div class="buttons">
          <tw-button v-if="goodsEditable" type="default" size="medium" @click="closeDrawer">Cancel</tw-button>
          <tw-button v-if="goodsEditable" type="primary" size="medium" @click="commitDrawer">OK</tw-button>
          <tw-button v-else type="default" size="medium" @click="closeDrawer">Close</tw-button>
        </div>
      </el-drawer>

      <!-- 混載ドロワー -->
      <el-drawer :visible.sync="isGoodsQuantityMode" :append-to-body="true" custom-class="combine_detail" :before-close="closeQuantityDrawer" :size="917">
        <h2>{{$t('Label.Goods Quantity - Preview')}}</h2>
        <div class="goods-quantity-header">
          <!-- トグルボタン -->
          <tw-button type="secondary" size="small" class="toggle_button" :class="{ open: expand }" @click="openToggle">
            <img src="@/assets/images/icons/view_all.svg">
          </tw-button>
          <TwEntityButtonsForCombine :current="selectedEntityTypeCd" />
        </div>

        <div v-if="isGoodsQuantityMode"  class="table_scroller">
          <div v-for="trading in goodsQuantityData" :key="trading.tradingId">
            <h3 class="trading-name">{{trading.tradingName}}</h3>
            <table class="goods_table" cellspacing="0" cellpadding="0">
              <colgroup>
                <col style="width:44%;">
                <col style="width:18%;">
                <col style="width:18%;">
                <col style="width:18%;">
                <col style="width:2%;">
              </colgroup>
              <tr>
                <th>{{$t('Label.Flow Name/Goods')}}</th>
                <th>{{$t('Label.Remaining Qty')}}</th>
                <th>{{$t('Label.Qty')}}</th>
                <th colspan="2">{{$t('Label.Result')}}</th>
              </tr>
              <!-- Total -->
              <tr>
                <td class="total-label">
                  Total
                </td>
                <td class="quantity">{{trading.total.total | formatQuantity}}</td>
                <td class="quantity">{{(trading.total.quantity1 || 0) | formatQuantity}}</td>
                <td colspan="2" class="quantity">{{trading.total.result | formatQuantity}}</td>
              </tr>

              <template v-for="(flow, index) in trading.flows">
                <tr :class="{expand: flow.expand && flow.goodsAssignment && flow.goodsAssignment.length, 'combine-from': flow.isCombineFrom, 'combine-to': flow.isCombineTo}" :key="flow.uuid + '_' + index">
                  <td style="padding: 0">
                    <div class="combine-flow-info">
                      <div class="flow-label">
                        <div v-if="flow.isCombineTo" class="combine-label">Combine</div>
                        <div v-if="flow.isOther && flow.isMainFlow" class="other-label">Other</div>
                      </div>
                      <div class="expand-button" :class="{'can-expand': flow.goodsAssignment && flow.goodsAssignment.length}" @click="toggleExpand(flow)">
                        <span></span>
                      </div>
                      <div class="flow-border">
                        <span class="M" v-if="flow.isMainFlow">M</span>
                        <span class="S" v-if="flow.isSubFlow">S</span>
                      </div>
                      <div class="flow-name" style="white-space: nowrap;width: 215px">
                        <tw-overflow-tooltip v-if="flow.tradingManageName" :content="flow.tradingManageName" />
                      </div>
                    </div>
                  </td>
                  <td class="quantity">{{flow.total | formatQuantity}}</td>
                  <td class="quantity">{{(flow.quantity1 || 0) | formatQuantity}}</td>
                  <td colspan="2" class="quantity">{{flow.result | formatQuantity}}</td>
                </tr>
                <template v-if="flow.expand">
                  <tr v-for="(goods, index) in flow.goodsAssignment" :key="'goods_' + flow.uuid + '_' + index" class="goods-assignment-row" :class="{'combine-from': flow.isCombineFrom, 'combine-to': flow.isCombineTo}">
                    <td class="goods-name" style="white-space: nowrap">
                      <tw-overflow-tooltip v-if="goods.goodsShortName" :content="goods.goodsShortName" style="width: 265px" />
                    </td>
                    <td class="quantity">{{goods.total | formatQuantity}}</td>
                    <td class="quantity">{{(goods.quantity1 || 0) | formatQuantity}}</td>
                    <td colspan="2" class="quantity">{{goods.result | formatQuantity}}</td>
                  </tr>
                </template>
              </template>
            </table>
          </div>
        </div>

        <div class="buttons">
          <tw-button  type="default" size="medium" @click="closeQuantityDrawer">Cancel</tw-button>
          <tw-button type="primary" size="medium" @click="showPreview">OK</tw-button>
        </div>
      </el-drawer>

    </el-form>
    <div class="buttons">
      <tw-button v-if="isPreviewMode" type="default" size="medium" @click="cancelPreview">Cancel</tw-button>
      <tw-button v-else type="default" size="medium" @click="cancel">Cancel</tw-button>
      <tw-button v-if="viewOnly && editable" type="primary" size="medium" @click="edit">Edit</tw-button>
      <template v-else>
        <tw-button v-if="isPreviewMode" type="secondary" size="medium" :disabled="!selectedFlowUuid" @click="showQuantity">Goods Quantity</tw-button>
        <tw-button v-else type="secondary" size="medium" :disabled="!selectedFlowUuid" @click="showSelectFlowDialog">Select Flow</tw-button>
        <tw-button v-if="isPreviewMode" type="primary" size="medium" @click="register">Register</tw-button>
        <tw-button v-else type="primary" size="medium" :disabled="!checkedCombineFlows.length" @click="showQuantity">Next</tw-button>
      </template>
    </div>
    <!--フロー選択ダイアログ -->
    <el-dialog :title="$t('Label.Select Flow')" :visible.sync="dialogSelectFlowVisible" :close-on-click-modal="false" class="header_border" width="1200px" aria-label="Select Flow" :append-to-body="true" :fullscreen="true" :before-close="onDialogBeforeClose">
      <TwSelectFlowForCombine ref="selectFlowForCombine" v-if="dialogSelectFlowVisible" :flows="flows" @show-flow-detail="showFlowDetail" @add-flows="addFlows" />
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import TwPartialView from '@/components/templates/TwPartialView.vue';
import { COMBINE_FLOW_FLG, MAIN_COMBINE_FLG, OTHER_TRADING_FLG, PARTIAL_API_USE_CODE, SUBORDINATE_FLOW_FLG } from 'lib-tw-common';
import BigNumber from 'bignumber.js';
import TwEntityButtonsForCombine from '@/components/molecules/TwEntityButtonsForCombine';
import TwSelectFlowForCombine from '@/components/organisms/TwSelectFlowForCombine';
import TwOverflowTooltip from '@/components/atoms/TwOverflowTooltip';

export default {
  name: 'TwCombineView',
  extends: TwPartialView,
  components: {
    TwEntityButtonsForCombine,
    TwSelectFlowForCombine,
    TwOverflowTooltip
  },
  data() {
    return {
      apiUseCode: PARTIAL_API_USE_CODE.COMBINE,
      checkedCombineFlows: [], // チェックを付けたフローのuuidの配列
      isGoodsQuantityMode: false, // 数量確認モード表示フラグ
      isPreviewMode: false, // プレビューモード表示フラグ
      goodsQuantityData: [], // 数量確認ドロワーのデータ
      expand: true, // 数量確認ドロワーのトグルボタンの開閉フラグ
      dialogSelectFlowVisible: false, // 別取引選択ダイアログ表示フラグ
      addedFlows: [], // 追加した別取引
      selectedFlowUuid: null, // 混載先のフロー
      selectedEntityTypeCd: null, // 混載先のエンティティ
      selectedEntityUncombinableInfo: [], // 混載先のエンティティの混載不可フロー情報
      OTHER_TRADING_FLG, // 別取引フラグ定数
      backupCombine: null, // プレビューモードキャンセル用のバックアップ
      backupOriginalCombine: null, // プレビューモードキャンセル用のバックアップ
      backupBeforeCombineStart: null, // 混載先フロー選択前のバックアップ
      backupCheckedCombineFlows: [], // プレビューモードキャンセル用のバックアップ
    };
  },
  computed: {
    // 取引管理画面で選択したフローかどうかを返却します
    isSelectedFlow() {
      return flow => {
        return flow.otherTradingFlg === OTHER_TRADING_FLG.OFF && flow.tradingFlowId === this.tradingFlowId;
      };
    },
    // 混載先に選択したエンティティと混載不可なフローかどうかを返却します
    isUncombinableFlow() {
      return flow => {
        return _.some(this.selectedEntityUncombinableInfo, info => {
          return info.tradingId === (flow.otherTradingId || this.tradingId) && info.tradingFlowId === flow.tradingFlowId;
        });
      }
    },
    // 削除可否
    deletable() {
      return flow => {
        if (this.viewOnly) {
          return false;
        }
        // プレビュー時は削除不可
        if (this.isPreviewMode) {
          return false;
        }
        // 混載先の取引のフローは削除不可
        if (flow.otherTradingFlg === OTHER_TRADING_FLG.OFF) {
          return false;
        }

        // 初期レスポンスに含まれている混載済みの別取引フローは削除不可
        if (this.initialOthers.includes(flow.uuid)) {
          return false;
        }

        return flow.subordinateInfo.subFlg !== SUBORDINATE_FLOW_FLG.ON;
      }
    },
    // 画面に存在するフローの取引IDとフローIDの配列を返却します
    flows() {
      return this.form.partialInfo.map(flow => {
        return `${flow.otherTradingId || this.tradingId}-${flow.tradingFlowId}`;
      });
    }
  },
  methods: {
    // 混載先のフローとエンティティを選択します
    combineStart(entity, flowIndex) {
      this.backupBeforeCombineStart = _.cloneDeep(this.form.partialInfo);
      const combineTo = this.form.partialInfo[flowIndex];
      // 選択したエンティティがフローの先頭の時
      if (this.isFirstEntity(combineTo, entity)) {
        // 混載先のフローを選択
        this.selectedFlowUuid = combineTo.uuid;
        // 混載先のエンティティを選択
        this.selectedEntityTypeCd = entity.entityTypeCd;
        combineTo.subordinateInfo.parentFlowInfo[0].mainCombineFlg = MAIN_COMBINE_FLG.ON;
      } else {
        const whenCombine = true; // 混載時フラグ
        const newFlow = this.partial(entity, flowIndex, whenCombine);
        // 混載先のフローに分納後の新しいフローを指定
        this.selectedFlowUuid = newFlow.uuid;
        // 混載先のエンティティを選択
        this.selectedEntityTypeCd = entity.entityTypeCd;
      }
      this.combineStartFlowId = combineTo.tradingFlowId;
      this.selectedEntityUncombinableInfo = entity.uncombinableInfo;
    },
    // フローチェック時の処理
    onCombineCheck(checked, flowUuid) {
      if (checked) {
        this.checkedCombineFlows.push(flowUuid);
      } else {
        const index = _.findIndex(this.checkedCombineFlows, uuid => uuid === flowUuid);
        this.checkedCombineFlows.splice(index, 1);
      }
    },
    // 主混載フローを解除します
    releaseMainCombineFlow() {
      this.selectedFlowUuid = null;
      this.selectedEntityTypeCd = null;
      this.combineStartFlowId = null;
      this.selectedEntityUncombinableInfo = [];
      this.checkedCombineFlows = [];
      this.form.partialInfo = [..._.reject(this.backupBeforeCombineStart, 'otherTradingFlg'), ..._.filter(this.form.partialInfo, 'otherTradingFlg')];
    },
    // フローを混載します
    combine(entityTypeCd, flowIndex) {
      // 混載先のフロー
      const combineTo = _.find(this.form.partialInfo, {uuid: this.selectedFlowUuid});
      // 混載元のフロー
      const copySource = this.form.partialInfo[flowIndex];
      // 混載元のエンティティ
      const entity = this.getEntity(copySource, entityTypeCd);
      // 混載元のエンティティ分割位置
      const entityIndex = _.findIndex(copySource.entityStatus, {entityTypeCd: entityTypeCd});
      // 混載元の親フロー（先頭エンティティ以外は混載元フロー）
      const parents = !entityIndex ? _.filter(this.form.partialInfo, info => this.isParentUuidMatch(copySource, info.uuid)) : [copySource];
      // 混載元の子フロー
      const children = this.getChild(copySource);
      _.forEach(parents, parent => {
        if (!_.some(combineTo.entityStatus[0].prevEntityStatus, {prevUuid: parent.uuid})) {
          // 混載先に混載元が存在しない場合
          // 混載先の接続情報を追加
          const newPrevEntityStatus = {
            prevEntityId: entity.prevEntityStatus[0].prevEntityId,
            prevEntityTypeCd: entity.prevEntityStatus[0].prevEntityTypeCd,
            prevUuid: parent.uuid,
            prevTradingFlowId: parent.tradingFlowId,
            prevTradingId: parent.otherTradingId || this.tradingId,
            isHighlight: true,
          };

          // prevEntityStatusをindex順にソートする
          const prevEntityStatus = _.orderBy([...combineTo.entityStatus[0].prevEntityStatus, newPrevEntityStatus], entityStatus => _.findIndex(this.form.partialInfo, {uuid: entityStatus.prevUuid}), ['asc']);

          // 混載先に反映
          combineTo.entityStatus[0].prevEntityStatus = prevEntityStatus;

          // 混載先の従属情報をセット
          const parentFlowInfo = {
            parentTradingId: parent.otherTradingId || this.tradingId,
            parentFlowId: parent.tradingFlowId,
            parentUuid: parent.uuid,
            mainCombineFlg: MAIN_COMBINE_FLG.OFF,
          }

          const combineToSubordinateInfo = {
            ...combineTo.subordinateInfo,
            subFlg:  SUBORDINATE_FLOW_FLG.ON,
            combineFlg: COMBINE_FLOW_FLG.ON,
            parentFlowInfo: _.orderBy([...combineTo.subordinateInfo.parentFlowInfo, parentFlowInfo], info => _.findIndex(this.form.partialInfo, {uuid: info.parentUuid}), ['asc']),
          };

          // 混載先に反映
          combineTo.subordinateInfo = combineToSubordinateInfo;

            // 混載元の親フローの従属情報をセット
          const subordinateInfo = {
            ...parent.subordinateInfo,
            parentFlg:  SUBORDINATE_FLOW_FLG.ON,
          }

          // 混載元の親フローに反映
          parent.subordinateInfo = subordinateInfo;
        }
      });



      if (entityIndex > 0) {
        // 先頭のエンティティじゃないとき
        // 子フローがあれば子フローを削除
        if (children && children.length) {
          children.forEach(child => {
            this.deleteFlow(child, true);
          });
        }

        // 混載元のエンティティを切り捨てる
        copySource.entityStatus.splice(entityIndex);
        this.$set(this.form.partialInfo, flowIndex, copySource);
      } else {
        // 先頭のエンティティの時
        // 混載元フローを削除
        this.deleteFlow(copySource, true);
      }
    },
    async showQuantity() {
      if (this.isPreviewMode) {
        this.cancelPreview();
        await this.$nextTick();
      }

      this.expand = true;

      // uuidの配列をindexの配列に変換
      const flowIndex = _.map(this.checkedCombineFlows, uuid => {
        return _.findIndex(this.form.partialInfo, {uuid: uuid});
      });
      // チェックが入ったフローのインデックスの配列を昇順にソート
      const sorted = _.orderBy(flowIndex, index => index, ['asc']);

      // 混載先のフロー
      const combineTo = _.find(this.form.partialInfo, {uuid: this.selectedFlowUuid});

      const index = _.findIndex(this.form.partialInfo, {uuid: this.selectedFlowUuid});


      // すでに混載されている混載元フローのuuidの配列
      const alreadyCombinedFlows = _.reduce(sorted, (ary, flowIndex) => {
        const flow = this.form.partialInfo[flowIndex];
        // フローの子フローを取得
        const children = this.getChild(flow);

        // 混載済みの子フロー
        const combinedFlow = _.find(children, child => child.subordinateInfo.combineFlg === COMBINE_FLOW_FLG.ON);
        if (combinedFlow) {
          const parentFlowInfo = _.map(_.reject(combinedFlow.subordinateInfo.parentFlowInfo, {mainCombineFlg: MAIN_COMBINE_FLG.ON}), 'parentUuid');
          ary = ary.concat(parentFlowInfo);
        }
        return ary;
      }, []);

      const alreadyCombinedFlowsIndex = _.map(alreadyCombinedFlows, uuid => {
        return _.findIndex(this.form.partialInfo, {uuid: uuid});
      });

      // 先に商品を連結
      const _goodsAssignment = [...sorted, ...alreadyCombinedFlowsIndex].reduce((ary, flowIndex) => {
        const flow = this.form.partialInfo[flowIndex];
        const entity = this.getEntity(flow, this.selectedEntityTypeCd);
        ary = ary.concat(_.map(entity.goodsAssignment, goods => {
          return {
            ...goods,
            quantity1: goods.quantity1 || 0,
            flowIndex: flowIndex,
          };
        }));

        return ary;
      },  _.cloneDeep(combineTo.entityStatus[0].goodsAssignment));
      const goodsAssignment = _.map(_goodsAssignment, goods => {
        const remainingQuantity = this.calcRemainingQuantity(goods, goods.flowIndex || index);
        return {
          ...goods,
          total: remainingQuantity.total,
          result: remainingQuantity.result,
        };
      });

      // 混載元の商品は0にする
      const toZero = goods => {
        return {
          ...goods,
          quantity1: 0,
          total: 0,
          result: 0,
        };
      };

      const getChild = (ary = [], flow) => {
        const children = this.getChild(flow);
        ary.push(flow.uuid);
        if (children.length) {
          return children.reduce((sum, child) => {
            sum = sum.concat(getChild([], child));
            return sum;
          }, []);
        } else {
          return ary;
        }
      }

      // 混載元フローの子孫を再帰的に取得する
      const checkedCombineFlowsChildren = this.checkedCombineFlows.reduce((ary, flowUuid) => {
        const flow = _.find(this.form.partialInfo, {uuid: flowUuid});
        return ary.concat(getChild(ary, flow));
      }, []);


      // 商品サマリーを取得して加工
      const mappedSummary = this.getGoodsAssignmentList(this.selectedEntityTypeCd).map(flow => {
        const isCombineTo = flow.uuid === this.selectedFlowUuid;
        const isCombineFrom = checkedCombineFlowsChildren.includes(flow.uuid) || alreadyCombinedFlows.includes(flow.uuid);

        const flowGoodsAssignment = isCombineFrom ? _.map(flow.goodsAssignment, toZero) : isCombineTo ? goodsAssignment : flow.goodsAssignment;

        return {
          ...flow,
          goodsAssignment: flowGoodsAssignment,
          isOther: flow.otherTradingFlg === OTHER_TRADING_FLG.ON,
          isMainFlow: flow.subordinateInfo.subFlg === SUBORDINATE_FLOW_FLG.OFF && flow.subordinateInfo.parentFlg === SUBORDINATE_FLOW_FLG.ON,
          isSubFlow: flow.subordinateInfo.subFlg === SUBORDINATE_FLOW_FLG.ON,
          isCombineTo,
          isCombineFrom,
          quantity1: isCombineFrom ? 0 : isCombineTo ?  _.sumBy(goodsAssignment, 'quantity1') || 0 : flow.quantity1,
          total: isCombineFrom ? 0 : isCombineTo ? _.sumBy(goodsAssignment, 'total') || 0 : flow.total,
          result: isCombineFrom ? 0 : isCombineTo ? _.sumBy(goodsAssignment, 'result') || 0 : flow.result,
          expand: flowGoodsAssignment && flowGoodsAssignment.length, // アコーディオンの開閉状態（デフォルト開）
        };
      });

      const tradings = _.map(_.groupBy(mappedSummary, 'tradingId'), (val, key) => {
        const total = {
          quantity1: _.sumBy(val, 'quantity1'),
          total: _.sumBy(val, 'total'),
          result: _.sumBy(val, 'result'),
        };
        return {
          tradingId: key,
          tradingName: val[0].tradingName,
          flows: val,
          total: total,
        };
      });

      this.goodsQuantityData = tradings;
      this.isGoodsQuantityMode = true;
    },
    closeQuantityDrawer() {
      this.isGoodsQuantityMode = false;
    },
    // 混載プレビューモードに切り替えます
    showPreview() {
      this.closeQuantityDrawer();
      this.isPreviewMode = true;
      this.backupCombine = _.cloneDeep(this.form.partialInfo);
      this.backupOriginalCombine = _.cloneDeep(this.original);
      this.backupCheckedCombineFlows = _.cloneDeep(this.checkedCombineFlows);

      // uuidの配列をindexの配列に変換
      const flowIndex = _.map(this.checkedCombineFlows, uuid => {
        return _.findIndex(this.form.partialInfo, {uuid: uuid});
      });
      // indexの昇順にソート
      const sorted = _.orderBy(flowIndex, index => index, ['asc']);
      // 削除されるフローがあるため、配列の後ろから処理する
      const reverseSorted = [...sorted].reverse();

      // 混載先のフロー
      const combineTo = _.find(this.form.partialInfo, {uuid: this.selectedFlowUuid});

      // すでに混載されている混載元フローのuuidの配列
      const alreadyCombinedFlows = _.reduce(sorted, (ary, flowIndex) => {
        const flow = this.form.partialInfo[flowIndex];
        // フローの子フローを取得
        const children = this.getChild(flow);

        // 混載済みの子フロー
        const combinedFlow = _.find(children, child => child.subordinateInfo.combineFlg === COMBINE_FLOW_FLG.ON);
        if (combinedFlow) {
          const parentFlowInfo = _.map(_.reject(combinedFlow.subordinateInfo.parentFlowInfo, {mainCombineFlg: MAIN_COMBINE_FLG.ON}), 'parentUuid');
          ary = ary.concat(parentFlowInfo);
        }
        return ary;
      }, []);

      const alreadyCombinedFlowsIndex = _.map(alreadyCombinedFlows, uuid => {
        return _.findIndex(this.form.partialInfo, {uuid: uuid});
      });

      // 先に商品を連結
      const goodsAssignment = [...sorted, ...alreadyCombinedFlowsIndex].reduce((ary, flowIndex) => {
        const flow = this.form.partialInfo[flowIndex];
        const entity = this.getEntity(flow, this.selectedEntityTypeCd);
        const entityIndex = _.findIndex(flow.entityStatus, {entityTypeCd: this.selectedEntityTypeCd});
        if (!entityIndex) {
          // 先頭のエンティティの時は親となるフローの商品情報で、数量のみ該当エンティティのものにする
          // https://tradewaltz.backlog.com/view/FY2023V2PH7-337
          const parentGoodsAssignment = this.getParentGoodsAssignment(flowIndex);
          ary = ary.concat(_.map(entity.goodsAssignment, goods => {
            const parentGoods = _.find(parentGoodsAssignment, o => o.goodsLineId === goods.prevGoodsLineId && o.goodsLineSeq === goods.prevGoodsLineSeq);
            return {
              ...parentGoods,
              quantity1: goods.quantity1 || 0,
              copyGoodsLineSeq: parentGoods.goodsLineSeq,
              copyGoodsLineId: parentGoods.goodsLineId,
              // 混載直後に参照する
              oldPrevGoodsLineSeq: goods.prevGoodsLineSeq,
              oldPrevGoodsLineId: goods.prevGoodsLineId,
            };
          }));
        } else {
          ary = ary.concat(_.map(entity.goodsAssignment, goods => {
            return {
              ...goods,
              quantity1: goods.quantity1 || 0,
              copyGoodsLineSeq: goods.goodsLineSeq,
              copyGoodsLineId: goods.goodsLineId,
            };
          }));
        }
        return ary;
      },  _.cloneDeep(combineTo.entityStatus[0].goodsAssignment));

      // 商品数量を計算
      const totalQuantity = new BigNumber(_.sumBy(goodsAssignment, 'quantity1')).dp(4).toNumber() || 0;

      // 混載元のフローを混載先に連結
      reverseSorted.forEach(flowIndex => {
        this.combine(this.selectedEntityTypeCd, flowIndex);
      });

      // 混載済みフローがある場合は、混載先のフローに接続情報を反映する
      const alreadyCombinedFlowsNewIndex = _.map(alreadyCombinedFlows, uuid => {
        return _.findIndex(this.form.partialInfo, {uuid: uuid});
      }).reverse();
      _.forEach(alreadyCombinedFlowsNewIndex, flowIndex => {
        this.combine(this.selectedEntityTypeCd, flowIndex);
      });

      // 混載先に商品を反映
      combineTo.entityStatus = combineTo.entityStatus.map(entity => {
        return {
          ...entity,
          goodsAssignment: goodsAssignment,
          totalAmountInfo: {
            ...entity.totalAmountInfo,
            totalQuantity: totalQuantity,
          }
        };
      });
    },
    // プレビューモードをキャンセルします
    cancelPreview() {
      this.isPreviewMode = false;
      this.form.partialInfo = this.backupCombine;
      this.original = this.backupOriginalCombine;
      this.checkedCombineFlows = this.backupCheckedCombineFlows;
      this.backupCombine = null;
      this.backupOriginalCombine = null;
      this.backupCheckedCombineFlows = [];
    },
    // 別取引選択ダイアログを表示します
    showSelectFlowDialog() {
      this.dialogSelectFlowVisible = true;
    },
    // 混載の表のアコーディオンの開閉を切り替えます
    toggleExpand(flow, expand) {
      if (typeof expand === 'boolean') {
        flow.expand = expand;
      } else if (flow.goodsAssignment && flow.goodsAssignment.length) {
        flow.expand = !flow.expand;
      } else {
        flow.expand = false;
      }
    },
    // 一括で開閉します
    openToggle() {
      this.expand = !this.expand;
      this.goodsQuantityData.forEach(trading => trading.flows.forEach(flow => {
        this.toggleExpand(flow, this.expand);
      }));
    },
    // 取引詳細を表示します
    showFlowDetail(row) {
      this.$emit('show-flow-detail', row);
    },
    // 別取引のフローを追加します
    addFlows(flows) {
      const otherFlows = this.getOthers();
      this.addedFlows = [...otherFlows, ...flows];
      this.dialogSelectFlowVisible = false;
      this.fetch(flows);
    },
    // 別取引のフローを削除するか確認します
    deleteFlowConfirm(flow) {
      this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete this data?')
        .then(() => {
          // 削除するフローの子フローを取得
          const children = this.getChild(flow);

          // 混載済みの子フロー
          const combinedFlow = _.find(children, child => child.subordinateInfo.combineFlg === COMBINE_FLOW_FLG.ON);
          // すでに混載されている混載元フローのuuidの配列
          let alreadyCombinedFlows = [];
          if (combinedFlow) {
            alreadyCombinedFlows = _.map(_.reject(combinedFlow.subordinateInfo.parentFlowInfo, {parentUuid:flow.uuid}), 'parentUuid');
          }

          // 子フローもまとめて削除
          this.deleteFlow(flow, true);
          // 混載済みフローの場合は他の混載元もまとめて削除
          alreadyCombinedFlows.forEach(uuid => {
            const flow = _.find(this.form.partialInfo, {uuid: uuid});
            this.deleteFlow(flow, true);
          });
        })
        .catch(() => {});
    },
    // 別取引選択ダイアログを閉じる前の確認
    onDialogBeforeClose(done) {
      if (this.$refs.selectFlowForCombine.selectFlows.length) {
        this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete this data?')
        .then(() => {
          done();
        })
        .catch(() => {});
      } else {
        done();
      }
    },
    // 別取引のフローの配列を返却します tradingId-tradingFlowIdの形式
    getOthers() {
      return _.map(_.filter(this.form.partialInfo, flow => flow.otherTradingFlg === OTHER_TRADING_FLG.ON), flow => `${flow.otherTradingId}-${flow.tradingFlowId}`);
    },
  }
}
</script>

<style lang="scss" scoped>
  .combine_view {
    z-index: 101;
    position: fixed;
    top: 82px;
    left: 0;
    bottom: 0;
    min-width: 100%;
    width: 100%;
    background: $color_gray_300;
    padding: 0 41px;
    overflow-x: auto;

    >.buttons {
      position: fixed;
      bottom: auto;
      top: 21px;
      padding-bottom: 0;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 38px;
    padding-right: 41px;
  }

  .flow_list_container {
    position: absolute;
    top: 0;
    left: 41px;
    right: 41px;
    bottom: 41px;
    overflow: hidden;
    background: $color_white;
    box-shadow: 0px 4px 8px rgba(170, 170, 204, 0.5);
    border-radius: 8px;

    .flow_area {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      background: $color_gray_100;

      .header {
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        height: 36px;
        display: flex;
        color: $color_gray_800;
        font-size: 12px;
        line-height: 18px;
        background: $color_white;
        z-index: 10;
        border-bottom: 1px solid $color_gray_400;

        div {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 100%;
          flex: 1;
        }

        .flow_label {
          width: 279px;
          height: 100%;
          flex: initial;
          border-right: 1px solid $color_gray_400;
        }
      }

      .flow {
        position: relative;
        width: 100%;
        min-width: 100%;
        height: calc(100% - 37px);
        overflow: auto;

        .left {
          position: sticky;
          top: 0;
          left: 0;
          width: 279px;
          height: 100%;
          z-index: 2;
        }

        .right {
          position: absolute;
          top: 0;
          left: 279px;
          min-width: calc(100% - 279px);
          min-height: 100%;
        }


        .flow_label {
          position: relative;
          width: 279px;
          height: 111px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-shrink: 0;
          border-bottom: 1px solid $color_gray_300;
          background: #ffffff;
          border-right: 1px solid $color_gray_400;
          padding: 0 12px;

          &.other-trading {
            background: $color_gray_100;
          }

          .main_label {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background: #8AC7FF;
            border-radius: 8px;
            height: 16px;
            align-self: flex-start;
            font-weight: bold;
            font-size: 10px;
            line-height: 14px;
            color: #ffffff;
            padding: 0 8px;
            margin-bottom: 4px;
            margin-right: 4px;
            width: 67px;
          }

          .other_label {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background: $color_gray_800;
            border-radius: 8px;
            height: 16px;
            align-self: flex-start;
            font-weight: bold;
            font-size: 10px;
            line-height: 14px;
            color: #ffffff;
            margin-bottom: 4px;
            width: 43px;
          }

          .combine_label {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background: white;
            border-radius: 8px;
            height: 16px;
            align-self: flex-start;
            font-weight: bold;
            font-size: 10px;
            line-height: 13px;
            color: $color_black;
            margin-bottom: 3px;
            width: 62px;
            border: 1px solid $color_black;;
          }

          .between {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            height: 40px;
            margin-top: 7px;

            .button_detail {
              margin-bottom: 4px;
              flex-shrink: 0;
              transform: translateZ(0);
            }

            .label-area {
              display: flex;
              align-items: center;
            }
          }

          .trading_name {
            display: inline-block;
            font-size: 14px;
            line-height: 20px;
            color: $color_black;
            font-weight: bold;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 210px;
          }

          .flow_name {
            font-size: 14px;
            line-height: 20px;
            color: $color_black;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .el-form-item {
            margin-bottom: 0;

            ::v-deep .el-input__inner {
              height: 28px;
              line-height: 28px;
              font-size: 14px;
              color: $color_gray_800;
            }

            ::v-deep .el-form-item__label {
              display: none;
            }
          }

          .close_times {
            position: absolute;
            display: block;
            width: 16px;
            height: 16px;
            top: 3px;
            right: 5px;
            cursor: pointer;
            &:hover {
              opacity: .5;
            }
          }
        }

        .entities {
          display: flex;
          height: 111px;
          padding: 0 25px 0 13px;
          flex-shrink: 0;
          border-bottom: 1px solid $color_gray_400;
          min-width: 100%;

          &.combine-to, &.selected {
            background: rgba(103, 239, 10, 0.2);
          }

          &.other-trading {
            background: $color_gray_300;
          }

          .entity {
            position: relative;
            width: 169px;
            padding: 15px 0;
            flex-shrink: 0;
            &:last-child {
              width: 117px!important;

              .flow_line {
                right: 121px!important;
              }
            }
          }
        }
      }
    }
  }
</style>

<style lang="scss">
  .partial_detail {
    overflow-x: hidden;

    .el-drawer__header {
      display: none;
    }

    .el-drawer__body {
      padding: 23px 19px 120px;

      .buttons {
        padding-right: 19px;
        padding-top: 24px;
        background: white;
      }
    }

    h2 {
      font-size: 16px;
      margin: 0 0 12px;
      padding-bottom: 15px;
      border-bottom: 1px solid #EDEDF4;
    }

    div.table_scroller {
      max-height: calc(100% - 130px);
      overflow: hidden;
      overflow-y: scroll;
    }

    table.goods_table {
      table-layout: fixed;
      min-width: 100%;
      border-collapse: collapse;
      background: #FFFFFF;
      font-size: 12px;
      color: $color_black;

      th {
        font-size: 12px;
        color: white;
        text-align: center;
        font-weight: normal;
        border-left: 1px solid $color_gray_300;
        border-right: 1px solid $color_gray_300;
        background: $color_dark_blue;
        padding: 4px 5px;
      }

      td {
        padding: 0 5px;
        border-left: 1px solid $color_gray_300;
        border-right: 1px solid $color_gray_300;
        border-bottom: 1px solid $color_gray_300;
        line-height: 29px;
        vertical-align: top;
      }

      td.quantity {
        text-align: right;

        &.input_cell {
          padding: 0;
        }

        &.minus {
          color: $color_warning;
        }
      }

      td.delete_cell {
        border-left: 0;
        padding:0;
        vertical-align:middle;

        img {
          display: block;
          margin: 0 auto;
          width: 20px;
          cursor: pointer;
          opacity: 0;
        }
      }


      tr:hover td {
        background-color: $color_gray_background;

        &.delete_cell img {
          opacity: .8;
        }
      }

      tr.selected td {
        background-color: $color_selected_row;
      }

      tr.selected:hover td {
        background-color: #ddf8ca;

        .el-form-item .el-input-number.is-without-controls .el-input__inner:focus {
          background: #ddf8ca!important;
        }
      }

      .el-form-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        margin: 0;

        .el-form-item__content {
          line-height: 29px;
          font-size: 12px;
          background: transparent;
        }

        .el-form-item__label {
          text-align: left;
          float: none;
          font-size: 12px;
          line-height: 20px;
          color: $color_black;
        }

        .el-input-number {
          width: 100%;
          height: 29px;
          line-height: 29px;
        }

        .el-input-number.is-without-controls .el-input__inner {
          display: block;
          height: 29px;
          padding: 0 4px;
          text-align: right;
          font-size: 12px;
          line-height: 29px;
          color: $color_gray_800;
          border-radius: 0;
          background: transparent;
          box-shadow: none;

          &:focus {
            background: #e1fcce!important;
          }

          &:not(:focus) {
            border: transparent;
          }
        }

        &:before, &:after {
          content: none;
        }

        .el-form-item__error {
          left: auto;
          right: 0;
          text-align: left;
          position: relative;
          font-size: 10px;
          padding: 0 5px;
          line-height: 1.1;
        }
      }
    }



    .goods {
      border-top: 1px solid #EDEDF4;
      padding-bottom: 12px;

      &:first-child {
        border-top: 0;
      }

      .goods_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: $color_black;
        border-bottom: 1px solid #EDEDF4;
        padding: 12px 0;

        &.empty {
          color: $color_gray_400;
          font-weight: normal;
        }

        img.close {
          display: block;
          width: 30px;
          height: 30px;
        }
      }


      .quantity, .remaining_quantity  {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        font-size: 14px;
        line-height: 20px;
        color: $color_black;

        label {
          text-align: left;
          float: none;
          font-size: 14px;
          line-height: 20px;
          color: $color_black;
        }

        span {
          text-align: right;
          padding-right: 9px;

          &.minus {
            color: $color_warning;
          }
        }
      }

      .quantity {
        padding: 12px 0;
      }
    }

    .el-select {
      width: 288px;
      margin-top: 12px;
    }

    li.el-select-dropdown__item.empty {
      color: $color_gray_600;
    }
  }

  .goods_pulldown {
    max-width: 288px;
  }

  .combine_detail {
    overflow-x: hidden;

    .el-drawer__header {
      display: none;
    }

    .el-drawer__body {
      padding: 23px 0 120px 54px;

      .buttons {
        padding-right: 19px;
        padding-top: 24px;
        background: white;
      }
    }

    h2 {
      font-size: 16px;
      margin: 0 0 12px;
      padding-bottom: 15px;
      border-bottom: 1px solid #EDEDF4;
    }

    .goods-quantity-header {
      display: flex;
      justify-content: space-between;
      padding-right: 52px;
      margin-bottom: 16px;
    }

    h3.trading-name {
      width: 812px;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.5;
      margin-top: 24px;
      margin-bottom: 12px;
    }

    div.table_scroller {
      max-height: calc(100% - 130px);
      overflow: hidden;
      overflow-y: scroll;
      padding-left: 20px;
      margin-left: -20px;
      padding-bottom: 20px;
    }

    table.goods_table {
      table-layout: fixed;
      min-width: 812px;
      border-collapse: collapse;
      background: #FFFFFF;
      font-size: 12px;
      color: $color_black;
      border-radius: 6px;
      overflow: hidden;
      border: 0;
      @include card_shadow;

      th {
        height: 38px;
        font-size: 12px;
        color: white;
        text-align: center;
        font-weight: normal;
        border-left: 1px solid $color_gray_400;
        border-right: 1px solid $color_gray_400;
        background: $color_dark_blue;
        padding: 4px 5px;
      }

      td {
        height: 43px;
        padding: 0 5px;
        border-left: 1px solid $color_gray_400;
        border-right: 1px solid $color_gray_400;
        border-bottom: 1px solid $color_gray_400;
        line-height: 43px;
        vertical-align: top;
      }

      td.quantity {
        text-align: right;

        &.input_cell {
          padding: 0;
        }

        &.minus {
          color: $color_warning;
        }
      }

      td.delete_cell {
        border-left: 0;
        padding:0;
        vertical-align:middle;

        img {
          display: block;
          margin: 0 auto;
          width: 20px;
          cursor: pointer;
          opacity: 0;
        }
      }


      tr:hover td {
        &.delete_cell img {
          opacity: .8;
        }
      }

      tr.selected td {
        background-color: $color_selected_row;
      }

      tr.selected:hover td {
        background-color: #ddf8ca;

        .el-form-item .el-input-number.is-without-controls .el-input__inner:focus {
          background: #ddf8ca!important;
        }
      }

      tr td.total-label {
        padding-left: 86px;
        font-weight: bold;
        color: $color_black;
      }

      tr.goods-assignment-row td {
        background-color: $color_gray_100;

        &.goods-name {
          padding-left: 86px;
        }
      }

      tr.combine-to td {
        background-color: #F2FBEA;
      }

      tr.combine-from td {
        background-color: #c9c8c8;

        &.quantity {
          color: $color_warning;
        }
      }

      tr.expand {
        position: relative;
        @include list_shadow;
        z-index: 1;

        td {
          border-bottom: 0;
        }

        .combine-flow-info .expand-button span {
          transform: rotate(90deg);
        }
      }

      tr {
        td:first-child {
          border-left: 0;
        }

        td:last-child {
          border-right: 0;
        }
      }

      tr:last-child td {
        border-bottom: 0;
      }

      .combine-flow-info {
        display: flex;
        width: 100%;
        height: 100%;

        .flow-label {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 70px;
          padding-left: 12px;

          .combine-label {
            height: 16px;
            padding: 2px 5px 4px;
            line-height: 1;
            border-radius: 8px;
            border: 1px solid #3E3A39;
            font-size: 10px;
            font-weight: 700;
          }

          .other-label {
            height: 16px;
            padding: 2px 5px 4px;
            line-height: 1;
            border-radius: 8px;
            border: 1px solid transparent;
            font-size: 10px;
            font-weight: 700;
            color: white;
            background: $color_gray_800;
          }
        }

        .expand-button {
          width: 45px;
          height: 100%;

          &.can-expand {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            span {
              display: block;
              width: 13px;
              height: 13px;
              background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAOCAYAAAD0f5bSAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACcSURBVHgBpY67DYMwEEAvJkmdEVghnyaNFU+QFZIJkg1gBTZhg4OKChATwAj0SDZXAHKBrUO8xt/nZ4A9INYX7l2xTIT+IZYtYhGypYkQ4ExiGW+RZiJf1SV5qz7JqlZoVzkSYV5CnN7z6sgwuiA4fKS859xSSleuUt5ye9NV6ulLsVKPZO1wRTIZwPBV6tmBg0XS2jQ0/F2v72YEasMxqPf0eEwAAAAASUVORK5CYII=) no-repeat center
            }
          }


        }

        .flow-border {
          width: 26px;
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 13px;
            height: 100%;
            text-align: center;
            font-weight: bold;
            font-size: 12px;
            line-height: 20px;
            color: white;
          }

          span.M {
            background: #8AC7FF;
          }

          span.S {
            background: #DCEEFF;
            color: #70B7F8;
            margin-left: 13px;
          }
        }

        .flow-name {
          padding-left: 16px;
          padding-right: 16px;
        }

      }

      .el-form-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        margin: 0;

        .el-form-item__content {
          line-height: 29px;
          font-size: 12px;
          background: transparent;
        }

        .el-form-item__label {
          text-align: left;
          float: none;
          font-size: 12px;
          line-height: 20px;
          color: $color_black;
        }

        .el-input-number {
          width: 100%;
          height: 29px;
          line-height: 29px;
        }

        .el-input-number.is-without-controls .el-input__inner {
          display: block;
          height: 29px;
          padding: 0 4px;
          text-align: right;
          font-size: 12px;
          line-height: 29px;
          color: $color_gray_800;
          border-radius: 0;
          background: transparent;
          box-shadow: none;

          &:focus {
            background: #e1fcce!important;
          }

          &:not(:focus) {
            border: transparent;
          }
        }

        &:before, &:after {
          content: none;
        }

        .el-form-item__error {
          left: auto;
          right: 0;
          text-align: left;
          position: relative;
          font-size: 10px;
          padding: 0 5px;
          line-height: 1.1;
        }
      }
    }



    .goods {
      border-top: 1px solid #EDEDF4;
      padding-bottom: 12px;

      &:first-child {
        border-top: 0;
      }

      .goods_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: $color_black;
        border-bottom: 1px solid #EDEDF4;
        padding: 12px 0;

        &.empty {
          color: $color_gray_400;
          font-weight: normal;
        }

        img.close {
          display: block;
          width: 30px;
          height: 30px;
        }
      }


      .quantity, .remaining_quantity  {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        font-size: 14px;
        line-height: 20px;
        color: $color_black;

        label {
          text-align: left;
          float: none;
          font-size: 14px;
          line-height: 20px;
          color: $color_black;
        }

        span {
          text-align: right;
          padding-right: 9px;

          &.minus {
            color: $color_warning;
          }
        }
      }

      .quantity {
        padding: 12px 0;
      }
    }

    .el-select {
      width: 288px;
      margin-top: 12px;
    }

    li.el-select-dropdown__item.empty {
      color: $color_gray_600;
    }
  }
</style>
