<template>
  <div class="tw_customer">
    <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
      <div slot="content">{{longName}}</div>
      <span>
        <figure class="avatar table"><img :src="icon"></figure>
        <span style="display: block;overflow: hidden;white-space:nowrap;text-overflow: ellipsis;">{{shortName}}</span>
      </span>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: 'TwCustomer',
  props: {
    customer: Object,
    prefix: String
  },
  computed: {
    longName() {
      return this.customer[`${this.prefix}CompanyName`] + ' ' + this.customer[`${this.prefix}SectionName`];
    },
    shortName() {
      return this.customer[`${this.prefix}CompanyShortName`];
    },
    icon() {
      return this.customer[`${this.prefix}CompanyIcon`] || '/images/customer.png';
    }
  },
};
</script>

<style lang="scss">
  .tw_customer {
    flex-shrink: 0;
    span {
      display: flex;
      align-items:center;
      font-size: 12px;
      line-height: 20px;
      color: $color_black;

      figure.avatar.table {
        margin-right: 8px;
      }
    }
  }
</style>
