<template>
  <div class="save_pattern_inner">
    <div class="head">
      <div class="header">{{$t('Label.Save Pattern - New Trading')}}</div>
      <div class="button_area">
        <tw-button type="default" size="medium" @click="cancel">Cancel</tw-button>
        <tw-button type="primary" size="medium" :disabled="loading" @click="apply">Apply</tw-button>
      </div>
    </div>
    <div v-if="loading" v-loading="loading" class="content"></div>
    <div v-else class="content">
      <el-form :model="form" :rules="rules" ref="form" label-position="top">
        <section>
          <h2 class="required">{{$t('Label.Base Trading Pattern Name')}}</h2>
          <el-form-item :label="$t('Label.Base Trading Pattern Name')" prop="tradingPatternName" class="has-long-input">
            <el-input type="text" v-model="form.tradingPatternName" placeholder="Trading Name" show-word-limit maxlength="100"></el-input>
          </el-form-item>
          <h2 class="required">{{$t('Label.Owner')}}</h2>
          <el-form-item
            :label="$t('Label.Owner')"
            prop="ownerSectionId"
            class="has-long-input"
          >
            <el-select
              v-model="form.ownerSectionId"
              filterable
            >
              <el-option
                v-for="owner in searchCriteriaOwnerSections"
                :key="owner.sectionId"
                :label="owner.sectionShortName"
                :value="owner.sectionId"
              />
            </el-select>
          </el-form-item>
          <table class="trading_pattern_table">
            <tr>
              <th class="required">{{$t('Label.Trading Type')}}</th>
              <td>
                <el-form-item :label="$t('Label.Trading Type')" prop="tradeType">
                  <el-radio-group v-model="form.tradeType">
                    <!-- ベース取引パターンの取引種別を引き継ぐ ・Export・Importの場合は変更可能。Off Shoreを非表示 ・Off Shoreの場合はExport・Importを非表示 -->
                    <el-radio v-for="item in isOffshore ? TRADE_TYPE_VARIABLES.slice(-1) : TRADE_TYPE_VARIABLES.slice(0, 2)" :key="item.code" :label="item.code" border>{{item.label}}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </td>
            </tr>
            <template v-if="!isOffshore">
              <tr>
                <th class="required">{{$t('Label.Payment Terms')}}</th>
                <td>
                  <el-form-item :label="$t('Label.Payment Terms')" prop="paymentTerms">
                    <el-radio-group v-model="form.paymentTerms">
                      <el-radio v-for="item in PAYMENT_TERMS_VARIABLES" :key="item.code" :label="item.code" border>{{item.label}}</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <th class="required">{{$t('Label.Trade Terms')}}</th>
                <td>
                  <el-form-item :label="$t('Label.Trade Terms')" prop="tradeTerms">
                    <el-select v-model="form.tradeTerms" placeholder="Select">
                      <el-option
                        v-for="item in DELIVERY_TERMS_VARIABLES"
                        :key="item.code"
                        :label="item.label"
                        :value="item.code">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <th class="required">{{$t('Label.Mode of Transport')}}</th>
                <td>
                  <el-form-item :label="$t('Label.Mode of Transport')" prop="modeOfTransport">
                    <el-radio-group v-model="form.modeOfTransport">
                      <el-radio v-for="item in MODE_OF_TRANSPORT_VARIABLES" :key="item.code" :label="item.code" class="mode_of_transport" border><i :class="[classNames[item.code]]" />{{item.label}}</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </td>
              </tr>
            </template>
          </table>
        </section>
        <template v-for="(flow, index) in flowList">
        <div :key="index">
          <section v-if="isOffshore">
            <div class="flow_type" :class="{ _im: flow.tradeType === 'Import' }">{{flow.tradeType}}</div>
            <table class="trading_pattern_table">
              <tr>
                <th class="required">{{$t('Label.Payment Terms')}}</th>
                <td>
                  <el-form-item :label="$t('Label.Payment Terms')" :prop="`paymentTerms${flow.tradeType}`">
                    <el-radio-group v-model="form[`paymentTerms${flow.tradeType}`]">
                      <el-radio v-for="item in PAYMENT_TERMS_VARIABLES" :key="item.code" :label="item.code" border>{{item.label}}</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <th class="required">{{$t('Label.Trade Terms')}}</th>
                <td>
                  <el-form-item :label="$t('Label.Trade Terms')" :prop="`tradeTerms${flow.tradeType}`">
                    <el-select v-model="form[`tradeTerms${flow.tradeType}`]" placeholder="Select">
                      <el-option
                        v-for="item in DELIVERY_TERMS_VARIABLES"
                        :key="item.code"
                        :label="item.label"
                        :value="item.code">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <th class="required">{{$t('Label.Mode of Transport')}}</th>
                <td>
                  <el-form-item :label="$t('Label.Mode of Transport')" :prop="`modeOfTransport${flow.tradeType}`">
                    <el-radio-group v-model="form[`modeOfTransport${flow.tradeType}`]">
                      <el-radio v-for="item in MODE_OF_TRANSPORT_VARIABLES" :key="item.code" :label="item.code" class="mode_of_transport" border><i :class="[classNames[item.code]]" />{{item.label}}</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </td>
              </tr>
            </table>
          </section>
          <div class="sub_buttons">
            <tw-button type="secondary" size="medium" @click="toggleSetSection(index)">Set Section</tw-button>
          </div>
          <section class="process_table_section">
            <tw-process-table
              mode="new"
              :schemas="processSchemas"
              :items="flow.entityStatus"
              :flow="flow"
              :processList="s.processList"
              :isSavePattern="true"
              :index="index"
              @remove-entity="removeEntity($event, index)"
              @select-company-role-change="selectCompanyRole($event, index)"
              :class="{ validation: shouldValidation }"
            />
          </section>
        </div>
        </template>
        <section class="keyword_section">
          <h2>{{$t('Label.Search Keyword')}}</h2>
          <table class="trading_pattern_table">
            <tr>
              <th>{{$t('Label.Keyword1')}}</th>
              <td>
                <el-form-item :label="$t('Label.Keyword1')" prop="keyword1" style="max-width: 900px">
                  <el-input type="text" v-model="form.keyword1" placeholder="" show-word-limit maxlength="100"></el-input>
                </el-form-item>
              </td>
            </tr>
            <tr>
              <th>{{$t('Label.Keyword2')}}</th>
              <td>
                <el-form-item :label="$t('Label.Keyword2')" prop="keyword2" style="max-width: 900px">
                  <el-input type="text" v-model="form.keyword2" placeholder="" show-word-limit maxlength="100"></el-input>
                </el-form-item>
              </td>
            </tr>
            <tr>
              <th>{{$t('Label.Keyword3')}}</th>
              <td>
                <el-form-item :label="$t('Label.Keyword3')" prop="keyword3" style="max-width: 900px">
                  <el-input type="text" v-model="form.keyword3" placeholder="" show-word-limit maxlength="100"></el-input>
                </el-form-item>
              </td>
            </tr>
            <tr>
              <th>{{$t('Label.Keyword4')}}</th>
              <td>
                <el-form-item :label="$t('Label.Keyword4')" prop="keyword4" style="max-width: 900px">
                  <el-input type="text" v-model="form.keyword4" placeholder="" show-word-limit maxlength="100"></el-input>
                </el-form-item>
              </td>
            </tr>
            <tr>
              <th>{{$t('Label.Keyword5')}}</th>
              <td>
                <el-form-item :label="$t('Label.Keyword5')" prop="keyword5" style="max-width: 900px">
                  <el-input type="text" v-model="form.keyword5" placeholder="" show-word-limit maxlength="100"></el-input>
                </el-form-item>
              </td>
            </tr>
          </table>
        </section>
        <section class="request_type_section">
          <table class="trading_pattern_table">
            <tr>
              <th>{{$t('Label.Request Type')}}</th>
              <td>
                <el-form-item :label="$t('Label.Request Type')">
                  <el-input type="text" v-model="form.requestType" placeholder="" :disabled="true" style="max-width:240px"></el-input>
                </el-form-item>
              </td>
            </tr>
          </table>
        </section>
      </el-form>
      <!-- Set Section -->
      <el-dialog :title="$t('Label.Set Section')" :visible.sync="isShowSetSection" width="617px" :show-close="false" :close-on-click-modal="false" :append-to-body="true" class="set_section header_border">
        <TwSetSection v-if="isShowSetSection" :tradingInfo="tradingInfo" @close="toggleSetSection(null)" @register="assignSection" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
// utility
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import mixinEntityList from '@/utils/mixinEntityList.js';
import mixinValidation from '@/utils/mixinValidation.js';
import { generateUuid, orderEntities } from '@/utils/searchUtil.js';
import { orderProcesses } from '@/utils/entity.js';
// components
import TwButton from '@/components/atoms/TwButton';
import TwSetSection from '@/components/organisms/TwSetSection';
import TwProcessTable from '@/components/molecules/TwProcessTable';
// dictionaries
import { SECTION_PREFIX, COMPANY_ROLE_CD, EDITABLE_FLG, DELETE_ABLE_FLG, DELIVERY_TERMS_VARIABLES, MODE_OF_TRANSPORT_VARIABLES, PAYMENT_TERMS_VARIABLES, TRADE_TYPE_VARIABLES, SECTION_SEARCH_PATTERN, OFFSHORE_TYPE, FLOW_TYPE, TRADE_TYPE, FLOW_TYPE_VARIABLES } from 'lib-tw-common';
import processSchemas from '@/dictionaries/manageTradingSavePatternListProcessSchema.json';
import { processNames } from '@/dictionaries/map.js';

// スタブ
// import tradingPatterns from '@/../public/tradingPatterns.json';

const classNames = {
  0: 'bulk',
  1: 'container',
  2: 'air'
};

export default {
  name: 'TwSavePattern',
  mixins: [mixinEntityList, mixinValidation],
  inject: ['s'],
  provide() {
    return {
      addProcess: this.addProcess,
      setProcess: this.setProcess,
      removeProcess: this.removeProcess,
      getPairFlows: this.getPairFlows
    };
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    tradingInfo: {
      type: Object,
      default: () => {}
    },
    currentFlow: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    TwButton,
    TwSetSection,
    TwProcessTable
  },
  data() {
    return {
      loading: false,
      isShowSetSection: false,
      setSectionFlowIndex: null,
      processSchemas: processSchemas,
      tradingPatterns: null, // 取引パターンレスポンス
      flowList: [],
      isOffshore: false, // 元となるベースパターンが三国間取引か
      searchCriteriaOwnerSections: [],
      form: {
        tradingPatternName: '',
        ownerSectionId: null,
        tradeType: null,
        // 非三国間の決済条件/貿易条件/輸送形態
        paymentTerms: null,
        tradeTerms: null,
        modeOfTransport: null,
        // 三国間、輸出の決済条件/貿易条件/輸送形態
        paymentTermsExport: null,
        tradeTermsExport: null,
        modeOfTransportExport: null,
        // 三国間、輸入の決済条件/貿易条件/輸送形態
        paymentTermsImport: null,
        tradeTermsImport: null,
        modeOfTransportImport: null,
        keyword1: '',
        keyword2: '',
        keyword3: '',
        keyword4: '',
        keyword5: '',
        requestType: 'New Request'
      },
      // 定数
      DELIVERY_TERMS_VARIABLES: DELIVERY_TERMS_VARIABLES,
      MODE_OF_TRANSPORT_VARIABLES: MODE_OF_TRANSPORT_VARIABLES,
      PAYMENT_TERMS_VARIABLES: PAYMENT_TERMS_VARIABLES,
      TRADE_TYPE_VARIABLES: TRADE_TYPE_VARIABLES,
      classNames,
      // validation
      shouldValidation: false, // バリデーション表示の可否
      rules: {
        tradingPatternName: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ['tw-isNotEmpty'],
            validator: this.onEventValidation,
            trigger: 'blur',
            required: true
          }
        ],
        ownerSectionId: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ['tw-isNotEmpty'],
            validator: this.onEventValidation,
            trigger: 'blur',
            required: true
          }
        ],
        tradeType: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ['tw-isNotEmpty'],
            validator: this.onEventValidation,
            trigger: 'blur',
            required: true
          }
        ],
        paymentTerms: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ['tw-isNotEmpty'],
            validator: this.onEventValidation,
            trigger: 'blur',
            required: true
          }
        ],
        paymentTermsExport: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ['tw-isNotEmpty'],
            validator: this.onEventValidation,
            trigger: 'blur',
            required: true
          }
        ],
        paymentTermsImport: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ['tw-isNotEmpty'],
            validator: this.onEventValidation,
            trigger: 'blur',
            required: true
          }
        ],
        tradeTerms: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ['tw-isNotEmpty'],
            validator: this.onEventValidation,
            trigger: 'blur',
            required: true
          }
        ],
        tradeTermsExport: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ['tw-isNotEmpty'],
            validator: this.onEventValidation,
            trigger: 'blur',
            required: true
          }
        ],
        tradeTermsImport: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ['tw-isNotEmpty'],
            validator: this.onEventValidation,
            trigger: 'blur',
            required: true
          }
        ],
        modeOfTransport: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ['tw-isNotEmpty'],
            validator: this.onEventValidation,
            trigger: 'blur',
            required: true
          }
        ],
        modeOfTransportExport: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ['tw-isNotEmpty'],
            validator: this.onEventValidation,
            trigger: 'blur',
            required: true
          }
        ],
        modeOfTransportImport: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ['tw-isNotEmpty'],
            validator: this.onEventValidation,
            trigger: 'blur',
            required: true
          }
        ]
      }
    };
  },
  computed: {
    flow() {
      const currentFlow = _.find(this.items, { tradingFlowId: this.currentFlow.tradingFlowId }) || _.find(this.items, { flowUuid: this.currentFlow.flowUuid }) || [];
      return {
        ...currentFlow,
        unAddableArea: []
      };
    }
  },
  async created() {
    this.loading = true;
    Promise.all([this.fetch(), this.fetchOwner()])
      .then(() => (this.loading = false))
      .catch(() => {
        this.loading = false;
        this.$emit('close');
      });
  },
  mounted() {
    // this.$refs.closeButton.focus();
  },
  watch: {},
  methods: {
    async fetch() {
      const params = {
        // bff_trading_pattern_15 取引パターン取得（トラン用）BFF
        lslConfig: {
          serviceCode: 'tw-user-entity-bff-api',
          apiCode: 'get_/v1/{companyId}/trading-patterns/{tradingPatternId}/transaction',
          query: {
            tradingCreateDate: this.tradingInfo.tradingCreateDate
          },
          path: {
            companyId: this.$store.state.companyId,
            tradingPatternId: this.tradingInfo.tradingPatternInfo.tradingPatternId
          }
        }
      };

      await $api
        .request(params)
        .then(async res => {
          this.tradingPatterns = res.tradingPatterns;
          const tradingPattern = _.get(res, 'tradingPatterns[0]', {});
          // 三国間取引かどうかの判定 ベースパターンがNOT_OFFSHOREでない
          // NOTE: 選択したフローにtradingChannelNoまたはchannelFlowNoが存在しない場合は、三国間のペアを特定できないため二国間として処理する（TC Noは基本必須項目のため起こり得ないケースではある）
          this.isOffshore = tradingPattern.offshoreType !== OFFSHORE_TYPE.NOT_OFFSHORE && (_.get(this.currentFlow, 'tradingChannelInfo.tradingChannelNo') || _.get(this.currentFlow, 'tradingChannelInfo.channelFlowNo'));
          this.form = {
            ...this.form,
            ownerSectionId: tradingPattern.ownerSectionId,
            tradeType: this.isOffshore ? TRADE_TYPE.OFF_SHORE : tradingPattern.tradeType,
            paymentTerms: tradingPattern.paymentTerms,
            tradeTerms: tradingPattern.tradeTerms,
            modeOfTransport: tradingPattern.modeOfTransport,
            keyword1: tradingPattern.keyword1,
            keyword2: tradingPattern.keyword2,
            keyword3: tradingPattern.keyword3,
            keyword4: tradingPattern.keyword4,
            keyword5: tradingPattern.keyword5
          };
          const flows = this.isOffshore ? this.getPairFlows(this.currentFlow) : [this.currentFlow];
          this.flowList = _.map(flows, flow => {
            return this.createEntityStatus(flow, res.tradingPatterns);
          });
          // console.log(flows, this.flowList);
          return Promise.resolve();
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          this.$emit('close');
          return Promise.reject();
        });
    },
    // オーナーセクション情報取得
    async fetchOwner() {
      const params = {
        lslConfig: {
          // bff_section_9 セクション検索共通BFF
          serviceCode: 'tw-user-entity-bff-api',
          apiCode: 'get_/v1/{companyId}/sections/common-search',
          query: {
            sectionSearchPattern: SECTION_SEARCH_PATTERN.PATTERN_3
            // Pattern1	呼び出し元：組織マスタ、組織_セクション紐づけマスタ（一覧領域）
            // Pattern2	呼び出し元：組織_セクション紐づけマスタ（詳細領域）
            // Pattern3	呼び出し元：セクションマスタ
            // Pattern4	呼び出し元：取引パターンマスタ一覧
            // Pattern5	呼び出し元：取引パターンマスタ詳細、商品マスタ詳細、トランの商品詳細
            // Pattern6	呼び出し元：商品マスタ一覧
          },
          path: {
            companyId: this.$store.state.companyId
          }
        }
      };

      await $api
        .request(params)
        .then(async res => {
          this.searchCriteriaOwnerSections = res.sections;
          return Promise.resolve();
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          this.$emit('close');
          return Promise.reject();
        });
    },
    // 選択中のFlowのprocessStatusにprocessRelationのRole情報をマージ
    // 三国間の場合はトラン側のフローに相当するマスタ側のベースフローとマージする
    createEntityStatus(flow, tradingPatterns) {
      const flowType = _.get(flow, 'tradingChannelInfo.flowType');
      let baseFlow = tradingPatterns[0]; // ベース取引パターンのフロー
      if (this.isOffshore) {
        if (flowType === FLOW_TYPE.EXPORT) {
          baseFlow = _.find(tradingPatterns, { offshoreType: OFFSHORE_TYPE.EXPORTER_SIDE });
        } else if (flowType === FLOW_TYPE.IMPORT) {
          baseFlow = _.find(tradingPatterns, { offshoreType: OFFSHORE_TYPE.IMPORTER_SIDE });
        }
      }
      // console.log(flow);
      let result = {
        entityStatus: _.map(flow.entityStatus, entity => {
          return {
            ...entity,
            processStatus: _.map(entity.processStatus, process => {
              const targetProcess = _.find(baseFlow.processRelation, { processId: process.processId });
              process = {
                ...process,
                editableFlg: EDITABLE_FLG.ON,
                deletableFlg: DELETE_ABLE_FLG.OK
              };
              if (targetProcess) {
                process = this.setFromToRole(process, targetProcess);
              }
              return process;
            })
          };
        }),
        tradingChannelInfo: _.get(flow, 'tradingChannelInfo', null),
        unAddableArea: this.isOffshore ? flow.unAddableArea : [],
        offshoreType: baseFlow.offshoreType
      };
      result.entityStatus = [...orderProcesses(orderEntities(result.entityStatus))];
      if (this.isOffshore) {
        // 三国間取引の場合は、決済条件/貿易条件/輸送形態を追加
        result.flowType = flowType;
        result.tradeType = this.getValue(flowType, FLOW_TYPE_VARIABLES) || '';
        if (flowType === FLOW_TYPE.EXPORT) {
          this.form.paymentTermsExport = baseFlow.paymentTerms;
          this.form.tradeTermsExport = baseFlow.tradeTerms;
          this.form.modeOfTransportExport = baseFlow.modeOfTransport;
        } else if (flowType === FLOW_TYPE.IMPORT) {
          this.form.paymentTermsImport = baseFlow.paymentTerms;
          this.form.tradeTermsImport = baseFlow.tradeTerms;
          this.form.modeOfTransportImport = baseFlow.modeOfTransport;
        }
      }
      // console.log(this.form);
      return result;
    },
    setFromToRole(process, targetProcess) {
      // 選択元フローと取引パターンのFrom,Toが一致している場合は、取引パターンのカンパニーロールを設定
      // 一致していない場合はブランクを設定する
      if (process.fromCompanyId === targetProcess.fromCompanyId && process.fromSectionId === targetProcess.fromSectionId) {
        process.fromRoleId = targetProcess.fromCompanyRoleId;
      } else {
        process.fromRoleId = null;
      }
      if (process.toCompanyId === targetProcess.toCompanyId && process.toSectionId === targetProcess.toSectionId) {
        process.toRoleId = targetProcess.toCompanyRoleId;
      } else {
        process.toRoleId = null;
      }
      return process;
    },
    // カンパニーロール変更
    selectCompanyRole({ row, key }, index) {
      const targetFlow = this.flowList[index];
      const targetEntity = _.find(targetFlow.entityStatus, { entityTypeCd: row.entityTypeCd });
      let targetProcess = _.find(targetEntity.processStatus, { processId: row.processId });
      targetProcess[key] = row[key];
    },
    // プロセスを追加します
    addProcess({ entityTypeCd, processId, processType }, index) {
      const targetFlow = this.flowList[index];
      const targetBaseFlow = _.find(this.tradingPatterns, { offshoreType: targetFlow.offshoreType });
      const targetProcess = targetBaseFlow ? _.find(targetBaseFlow.processRelation, { processId: processId }) : null;
      const newProcess = {
        processType: processType,
        processId: processId,
        processName: _.get(_.find(processNames, { processType: processType }), 'nameEn'),
        processUserMemo: '',
        fromRoleId: targetProcess ? targetProcess.fromCompanyRoleId : '',
        fromCustomerId: '',
        fromCompanyId: '',
        fromSectionId: '',
        fromSectionName: '',
        toRoleId: targetProcess ? targetProcess.toCompanyRoleId : '',
        toCustomerId: '',
        toCompanyId: '',
        toSectionId: '',
        toSectionName: '',
        uuid: generateUuid(),
        editableFlg: EDITABLE_FLG.ON,
        deletableFlg: DELETE_ABLE_FLG.OK
      };
      const targetEntity = _.find(targetFlow.entityStatus, { entityTypeCd: entityTypeCd });
      if (targetEntity) {
        targetEntity.processStatus.push(newProcess);
      } else {
        targetFlow.entityStatus.push({
          entityTypeCd: entityTypeCd,
          processStatus: [newProcess]
        });
      }
      const ordered = orderProcesses(orderEntities(targetFlow.entityStatus));
      targetFlow.entityStatus.splice(0, targetFlow.entityStatus.length);
      targetFlow.entityStatus.push(...ordered);
      // console.log(targetFlow.entityStatus)
    },
    // プロセスを更新します
    setProcess(o, p, index) {
      const targetFlow = this.flowList[index];
      const process = _.find(_.find(targetFlow.entityStatus, { entityTypeCd: p.entityTypeCd }).processStatus, o => {
        return o.processId === p.processId && (o.processSeq ? o.processSeq === p.processSeq : o.uuid === p.uuid);
      });
      if (o.sectionId.startsWith(SECTION_PREFIX.SECTION_PREFEX)) {
        process[`${p.fromTo}CustomerId`] = o.tradingId;
        process[`${p.fromTo}CompanyId`] = null;
      } else {
        process[`${p.fromTo}CompanyId`] = o.tradingId;
        process[`${p.fromTo}CustomerId`] = null;
      }
      process[`${p.fromTo}CompanyShortName`] = o.tradingShortName;
      process[`${p.fromTo}CompanyName`] = o.tradingName;
      process[`${p.fromTo}SectionId`] = o.sectionId;
      process[`${p.fromTo}SectionName`] = o.sectionName;
      process[`${p.fromTo}SectionShortName`] = o.sectionShortName;
    },
    // プロセスを削除します
    removeProcess(p, index) {
      const targetFlow = this.flowList[index];
      const entity = _.find(targetFlow.entityStatus, { entityTypeCd: p.entityTypeCd });
      const findIndex = _.findIndex(entity.processStatus, o => {
        return o.processId === p.processId && (o.processSeq ? o.processSeq === p.processSeq : o.uuid === p.uuid);
      });
      entity.processStatus.splice(findIndex, 1);
      if (_.isEmpty(entity.processStatus)) {
        this.removeEntity(p, index);
      }
    },
    // エンティティを削除します
    removeEntity(p, index) {
      const targetEntityStatus = this.flowList[index].entityStatus;
      const entityIndex = _.findIndex(targetEntityStatus, { entityTypeCd: p.entityTypeCd });
      targetEntityStatus.splice(entityIndex, 1);
    },
    // Cancel操作
    cancel() {
      this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete this data?')
        .then(() => {
          this.$emit('close');
        })
        .catch(() => {});
    },
    // 取引パターン登録・承認依頼
    async apply() {
      this.$store.commit('START_PROCESS');
      this.shouldValidation = true;
      const validationCheck = await this.validationCheck('form');
      const hasEmptySections = await this.hasEmptySections();
      if (
        hasEmptySections ||
        !validationCheck ||
        _.some(this.flowList, flow => {
          return !flow.entityStatus.length;
        })
      ) {
        // バリデーションチェック
        this.$store.commit('END_PROCESS');
        this.$store.dispatch('SHOW_ALERT', 'Please check the input and try again.');
        return;
      }
      const params = {
        // bff_trading_pattern_16 取引パターン登録（トラン用）BFF
        lslConfig: {
          serviceCode: 'tw-user-entity-bff-api',
          apiCode: 'post_/v1/{companyId}/trading-patterns/transaction',
          path: {
            companyId: this.$store.state.companyId
          }
        },
        data: {
          tradingPatterns: _.map(this.flowList, flow => {
            return this.createRequestParams(flow);
          })
        }
      };
      // console.log(params);
      await $api
        .request(params)
        .then(() => {
          this.$store.commit('END_PROCESS');
          this.$emit('close');
          this.$store.dispatch('SHOW_COMPLETED');
        })
        .catch(err => {
          this.$store.commit('END_PROCESS');
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    // 取引パターン登録BFFリクエストの形式にパラメータ生成
    createRequestParams(flow) {
      const form = this.form;
      let sortNoCount = 1;
      let result = {
        processRelation: _.flatMap(flow.entityStatus, entity => {
          return _.map(entity.processStatus, process => {
            process = {
              entityType: entity.entityTypeCd,
              // processSeq, entitySeqは、現状の実装では常に1(マスタ側の仕様と同様)
              entitySeq: 1,
              processId: process.processId,
              // processSortNoは連番を発行(マスタ側と同じ処理に合わせる)
              processSortNo: sortNoCount,
              processSeq: 1,
              fromCompanyRoleId: process.fromRoleId,
              fromSectionId: process.fromSectionId,
              toCompanyRoleId: process.toRoleId,
              toSectionId: process.toSectionId,
              processUserMemo: process.processUserMemo
            };
            sortNoCount += 1;
            return process;
          });
        }),
        offshoreType: flow.offshoreType,
        ownerSectionId: form.ownerSectionId,
        tradingPatternName: form.tradingPatternName,
        tradeType: form.tradeType,
        paymentTerms: form.paymentTerms,
        tradeTerms: form.tradeTerms,
        modeOfTransport: this.form.modeOfTransport,
        keyword1: form.keyword1 ? form.keyword1 : null,
        keyword2: form.keyword2 ? form.keyword2 : null,
        keyword3: form.keyword3 ? form.keyword3 : null,
        keyword4: form.keyword4 ? form.keyword4 : null,
        keyword5: form.keyword5 ? form.keyword5 : null
      };
      if (this.isOffshore) {
        // 三国間取引の場合は、決済条件/貿易条件/輸送形態を変更
        const flowType = flow.flowType;
        if (flowType === FLOW_TYPE.EXPORT) {
          result.paymentTerms = this.form.paymentTermsExport;
          result.tradeTerms = this.form.tradeTermsExport;
          result.modeOfTransport = this.form.modeOfTransportExport;
        } else if (flowType === FLOW_TYPE.IMPORT) {
          result.paymentTerms = this.form.paymentTermsImport;
          result.tradeTerms = this.form.tradeTermsImport;
          result.modeOfTransport = this.form.modeOfTransportImport;
        }
      }
      return result;
    },
    // 指定されていないセクションがあるかを返却します
    async hasEmptySections() {
      return !!this.$el.querySelector('.invalid');
    },
    // Set Section
    toggleSetSection(index) {
      this.setSectionFlowIndex = index;
      this.isShowSetSection = !this.isShowSetSection;
    },
    assignSection(exporter, importer, forwarder, overWrite = false) {
      const targetFlow = this.flowList[this.setSectionFlowIndex];
      if (exporter) targetFlow.entityStatus = this.setSection(exporter, COMPANY_ROLE_CD.EXPORTER, overWrite, targetFlow);
      if (importer) targetFlow.entityStatus = this.setSection(importer, COMPANY_ROLE_CD.IMPORTER, overWrite, targetFlow);
      if (forwarder) targetFlow.entityStatus = this.setSection(forwarder, COMPANY_ROLE_CD.EXPORT_FREIGHT_FORWARDER, overWrite, targetFlow);
      this.toggleSetSection(null);
    },
    setSection(section, role, overWrite, targetFlow) {
      return _.map(targetFlow.entityStatus, entity => {
        entity.processStatus = _.map(entity.processStatus, process => {
          if (process.fromRoleId === role) {
            if (overWrite || !process.fromSectionId) {
              process = this.setProcessSection(process, section, 'from');
            }
          }
          if (process.toRoleId === role) {
            if (overWrite || !process.toSectionId) {
              process = this.setProcessSection(process, section, 'to');
            }
          }
          return process;
        });
        return entity;
      });
    },
    setProcessSection(process, section, fromTo) {
      return {
        ...process,
        [`${fromTo}CustomerId`]: section.sectionId.startsWith(SECTION_PREFIX.SECTION_PREFEX) ? section.tradingId : null,
        [`${fromTo}CompanyId`]: section.sectionId.startsWith(SECTION_PREFIX.SECTION_PREFEX) ? null : section.tradingId,
        [`${fromTo}CompanyShortName`]: section.tradingShortName,
        [`${fromTo}CompanyName`]: section.tradingName,
        [`${fromTo}SectionId`]: section.sectionId,
        [`${fromTo}SectionName`]: section.sectionName,
        [`${fromTo}SectionShortName`]: section.sectionShortName,
        [`${fromTo}CompanyIcon`]: section.tradingIcon
      };
    },
    // 取り引きチャネルが同じフローを輸出、輸入の順で返却します
    getPairFlows(flow) {
      const tradingChannelNo = _.get(flow, 'tradingChannelInfo.tradingChannelNo');
      const channelFlowNo = _.get(flow, 'tradingChannelInfo.channelFlowNo');
      if (this.flowList.length) return this.flowList;
      let flows = [flow];
      if (tradingChannelNo) {
        flows = _.filter(this.items, item => {
          return _.get(item, 'tradingChannelInfo.tradingChannelNo') === tradingChannelNo;
        });
      } else if (channelFlowNo) {
        flows = _.filter(this.items, item => {
          return _.get(item, 'tradingChannelInfo.channelFlowNo') === channelFlowNo;
        });
      }
      return _.sortBy(flows, o => {
        return _.get(o, 'tradingChannelInfo.flowType');
      });
    },
    getValue(value, variables) {
      return _.get(_.find(variables, { code: value }), 'label', '');
    }
  }
};
</script>

<style lang="scss">
.save_pattern_drawer {
  .save_pattern_inner {
    overflow-y: scroll;
    z-index: 101;
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
    background: $color_gray_300;
    padding: 32px 40px;
    overflow-x: scroll;
    .head {
      display: flex;
      position: relative;
      margin-bottom: 24px;
      .header {
        font-size: 24px;
        font-weight: bold;
        line-height: 33px;
      }
      .button_area {
        margin-left: auto;
      }
    }
    div.content {
      padding: 32px;
      min-height: calc(100% - 64px);
      position: relative;
      background: white;
      box-shadow: 0px 4px 8px rgba(170, 170, 204, 0.5);
      border-radius: 6px;
    }
    h2 {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 12px;
      color: $color_gray_800;
    }
    section {
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 1px solid $color_gray_300;
      .el-form-item {
        margin-bottom: 0;
        &.has-long-input {
          margin-bottom: 24px;
          max-width: 900px;
          .el-select {
            width: 100%;
          }
        }
        .el-form-item__label {
          display: none;
        }
        .el-input__inner {
          padding-right: 60px;
        }
      }
      table.trading_pattern_table {
        width: 100%;
        border-collapse: collapse;
        padding: 0;
        tr:first-of-type {
          th, td {
            padding-top: 0;
          }
        }
        th {
          width: 168px;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          vertical-align: middle;
          text-align: left;
          padding: 20px 8px 0 0;
          color: $color_gray_800;
        }
        td {
          vertical-align: middle;
          padding: 20px 0 0;
          .el-radio.is-bordered {
            margin-bottom: 0;
            &.mode_of_transport {
              padding-top: 6px;
              padding-bottom: 0;
            }
            i {
              display: inline-block;
              width: 28px;
              height: 28px;
              margin-right: 4px;
              vertical-align: middle;
              background: no-repeat center;
              background-size: 28px auto;
              &.container {
                background-image: url(../../assets/images/icons/icon_container.svg);
              }
              &.bulk {
                background-image: url(../../assets/images/icons/icon_bulk.svg);
              }
              &.air {
                background-image: url(../../assets/images/icons/icon_air.svg);
              }
            }
            &.is-checked i {
              &.container {
                background-image: url(../../assets/images/icons/icon_container_dark.svg);
              }
              &.bulk {
                background-image: url(../../assets/images/icons/icon_bulk_dark.svg);
              }
              &.air {
                background-image: url(../../assets/images/icons/icon_air_dark.svg);
              }
            }
          }
          .el-select {
            width: 160px;
          }
        }
      }
      &.process_table_section {
        @include card_shadow;
        border-radius: 6px;
        padding-bottom: 0;
        .tw_table.process_table {
          border-radius: 6px 6px 0 0;
          th {
            padding: 7px 0;
            background: $color_gray_300;
          }
          thead tr:first-child:after {
            box-shadow: none;
          }
        }
        table {
          width: 100%;
          border-collapse: collapse;
          padding: 0;
        }
        th {
          width: 240px;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          vertical-align: middle;
          text-align: left;
          // padding: 0 8px 16px 0;
        }
        td {
          font-size: 16px;
          vertical-align: middle;
          // padding: 0 0 16px 0;
          &.select_role {
            .el-input {
              display: flex;
              align-items: center;
            }
            .el-input__inner {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              width: 208px;
              // padding-right: 32px;
            }
            .el-input__suffix {
              height: 32px;
              display: flex;
              align-items: center;
            }
          }
        }
        .validation .tw_table td {
          &.select_role {
            .invalid {
              .el-input__inner {
                border-color: $color_warning;
                &::placeholder {
                  color: transparent;
                }
              }
            }
            .invalid {
              &:after {
                height: 32px;
                line-height: 32px;
                display: block;
                color: $color_warning;
                content: 'Company Roleは入力必須です';
                font-size: 12px;
                position: absolute;
                top: 0;
                left: 12px;
              }
            }
          }
        }
      }
      &.keyword_section {
        border-top: 1px solid $color_gray_300;
        margin: 8px 0 0;
        padding-top: 24px;
        h2 {
          margin-bottom: 0;
        }
      }
      &.request_type_section {
        border-bottom: none;
        margin-bottom: 0;
      }
      .flow_type {
        font-size: 10px;
        color: $color_white;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 58px;
        height: 16px;
        margin-top: 32px;
        // margin-bottom: 12px;
        border-radius: 15.5px;
        background: linear-gradient(80.82deg, #4eb100 -17.87%, #c8e416 101.59%);
        &._im {
          background: linear-gradient(237.29deg, #fdca66 5.38%, #ff1203 90.56%);
        }
      }
    }
    .sub_buttons {
      margin-bottom: 32px;
    }
    h2,
    th {
      &.required:after {
        content: '*';
        color: #e00001;
        margin-left: 4px;
      }
    }
  }
}
</style>
