<template>
  <div class="tw_entity_buttons_for_combine">
    <tw-entity-icon v-for="entity in entityNames" :key="entity.entityId" :class="{current: isCurrent(entity), disabled: !isCurrent(entity)}" :entityName="entity.entityId" :popover="true" :size="size" color="color_shadow" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { entityNames } from '@/dictionaries/map.js';
import TwEntityIcon from '@/components/atoms/TwEntityIcon';

export default {
  name: 'TwEntityButtonsForCombine',
  props: {
    size: {
      type: String,
      default: 'ex_large'
    },
    current: Number,
  },
  components: {
    TwEntityIcon
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters(['canAccess']),
    entityNames() {
      return entityNames;
      // return _.filter(entityNames, entity => {
      //   if (entity.entityId === 'TRANSPORTATION_AG') {
      //     // 輸出契約の時
      //     return this.canAccess('ListTransportationAgreement');
      //   }
      //   return this.canAccess(entity.pageName);
      // });
    },
    isCurrent() {
      return entity => {
        return entity.code === this.current;
      }
    },
  },
};
</script>

<style lang="scss">
  .tw_entity_buttons_for_combine {
    display: flex;

    .tw_entity_icon {
      margin-left: 12px;
      margin-right: 0;

      &.current {
        cursor: default;

        &:hover i {
          opacity: 1;
        }
      }
    }
  }
</style>
