<template>
  <div
    class="card_container"
    :style="{
      background: getColor,
    }"
    @click="handleActive"
  >
  <div class="card_inner">
    <div class="head">
      <tw-entity-icon :entityName="entityName" size="24" color="white" />
      <!-- 分納 -->
      <div v-if="canPartial" class="partial_button" @click.stop.prevent="partial" />
      <!-- 混載 -->
      <template v-if="!isPreviewMode">
        <tw-button v-if="canCombineStart" type="primary" size="very_small" @click="combineStart">Select</tw-button>
        <div v-else-if="isMainCombineFlow" class="main-combine-check-wrap" @click.stop="releaseMainCombineFlow">
          <el-checkbox class="combine-check main-combine-check" :checked="true" />
        </div>

        <div v-else-if="canCombine" @click.stop.prevent="onCheck">
          <el-checkbox class="combine-check" v-model="checked" />
        </div>
      </template>
    </div>
    <div class="value">
      <div>{{formatQuantity}}</div>
      <!-- {{ isUncombinableFlow ? 'uncombinable' : '' }} -->
      <!-- <div>{{copyUuid}}</div> -->
      <!-- <div>{{formatCurrency}}</div> -->
    </div>
  </div>
</div>

</template>

<script>
import _ from 'lodash';
import { CAN_PARTIAL_SHIPMENT_FLG, CAN_COMBINE_FLG, SUBORDINATE_FLOW_FLG } from 'lib-tw-common';
import { entityNames } from '@/dictionaries/map.js';
import { color } from '@/assets/styles/styles.js';
import { formatQuantity, formatCurrency } from '@/utils/searchUtil.js';
import TwEntityIcon from '@/components/atoms/TwEntityIcon.vue';
import TwButton from '@/components/atoms/TwButton.vue';

export default {
  name: 'TwPartialCardItem',
  components: {
    TwEntityIcon,
    TwButton,
  },
  props: {
    item: Object,
    flowIndex: Number,
    entityOrder: Number,
    viewOnly: Boolean,
    isCombine: Boolean,
    // 取引管理で選択したフロー
    isSelectedFlow: Boolean,
    selectedFlowUuid: String,
    selectedEntityTypeCd: Number,
    isUncombinableFlow: Boolean,
    flowUuid: String,
    isPreviewMode: Boolean,
    checkedCombineFlows: Array,
    flowRelationalFlg: Number,
  },
  data() {
    return {
      active: false,
      checked: false,
    };
  },
  computed: {
    entityName() {
      return _.get(_.find(entityNames, {code: this.item.entityTypeCd}), 'entityId');
    },
    processes() {
      return this.item.processStatus;
    },
    getColor() {
      return color[`gradation_${this.entityName}`];
    },
    canPartial() {
      if (this.viewOnly || this.isCombine) {
        return false;
      }
      return this.item.canPartialShipmentFlg === CAN_PARTIAL_SHIPMENT_FLG.ON;
    },
    canCombineStart() {
      if (this.viewOnly || !this.isCombine || this.selectedFlowUuid) {
        return false;
      }
      // 選択したフローの一家のみ混載先に指定可能
      return this.flowRelationalFlg === SUBORDINATE_FLOW_FLG.ON && this.item.canCombineFlg === CAN_COMBINE_FLG.ON;
    },
    isMainCombineFlow() {
      if (this.viewOnly || !this.isCombine || !this.selectedFlowUuid) {
        return false;
      }
      return this.selectedFlowUuid === this.flowUuid && this.item.entityTypeCd === this.selectedEntityTypeCd
    },
    canCombine() {
      // 閲覧モード、分納モード、混載先を選択していない場合はfalse
      if (this.viewOnly || !this.isCombine || !this.selectedFlowUuid) {
        return false;
      }

      // 混載先フローの場合はfalse
      if (this.selectedFlowUuid === this.flowUuid) {
        return false;
      }

      // uncombinableInfoに該当する場合はfalse
      if (this.isUncombinableFlow) {
        return false;
      }

      // 選択したエンティティと同じエンティティかつ、混載可能フラグが立っている場合はtrue
      return this.item.entityTypeCd === this.selectedEntityTypeCd && this.item.canCombineFlg === CAN_COMBINE_FLG.ON;
    },
    formatQuantity() {
      return formatQuantity(Math.max(this.item.totalAmountInfo.totalQuantity, 0));
    },
    formatCurrency() {
      const currency = this.$store.getters.getCurrency(this.item.totalAmountInfo.currencyId);
      if (currency) {
        return formatCurrency(this.item.totalAmountInfo.totalAmount, this.item.totalAmountInfo.currency, currency.minorUnit);
      }
      return formatCurrency(this.item.totalAmountInfo.totalAmount, this.item.totalAmountInfo.currency);
    }
  },
  watch: {
    checkedCombineFlows(val) {
      if (_.isEmpty(val)) {
        this.checked = false;
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.handleClick, false);
    // プレビューモードキャンセル時に再度マウントされるので、チェック状態を取得
    if (_.isArray(this.checkedCombineFlows)) {
      this.checked = this.checkedCombineFlows.includes(this.flowUuid);
    }

  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClick, false);
  },
  methods: {
    handleActive() {
      if (this.active) {
        this.active = false;
        return;
      }
      window.requestAnimationFrame(() => {
        this.active = true;
        this.showDetail();
      });
    },
    showDetail() {
      this.$emit('show-partial-detail', this.item, this.flowIndex, this.entityOrder);
    },
    handleClick() {
      if (this.active) {
        this.active = false;
      }
    },
    partial() {
      this.$emit('partial', this.item, this.flowIndex);
    },
    combineStart() {
      this.$emit('combine-start', this.item, this.flowIndex, this.entityOrder);
    },
    onCheck() {
      this.checked = !this.checked;
      this.$emit('on-check', this.checked, this.flowUuid);
    },
    releaseMainCombineFlow() {
      this.$emit('release-main-combine-flow');
    },
  }
};
</script>

<style lang="scss" scoped>
.card_container {
  position: relative;
  width: 117px;
  height: 80px;
  border-radius: 5px;
  transition: 0.2s;
  // margin: 20px;
  cursor: pointer;
  border: 1px solid rgba(0,0,0,0);
  overflow: hidden;
  z-index: 1;
}

.card_inner {
  width: 100%;
  height: 78px;
  background: #fff;
  padding: 7px;
  @include card_shadow;
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  margin-bottom: 4px;

  .tw_entity_icon {
    margin-right: 4px;
  }

  .partial_button {
    width: 30px;
    height: 20px;
    border-radius: 4px;
    background: $color_gray_300 url(../../assets/images/icons/partial_plus.svg) no-repeat center / 16px auto;
  }

  .combine_button {
    width: 30px;
    height: 20px;
    border-radius: 4px;
    background: $color_gray_300 url(../../assets/images/icons/partial_plus.svg) no-repeat center / 16px auto;
  }
}

.value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 30px;
  font-size: 12px;
  line-height: 15px;
  color: #3E3A39;
  text-align: right;
  margin-top: 8px;
}
</style>
